.contactUsCard{
    margin-top:5%;
    margin-bottom:50px;
}

.contactImage{
    width: 100%;
    max-width: 60%;
    height: 100%;
    max-height: 60%;
}
.textAlignment{
    text-align: center;
}
@media only screen and (max-width: 480px) {
    .headerFont{
        white-space:break-spaces !important
    }
    .contactRowImage{
        width: auto;
        height: auto;
    }
}
@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .headerFont{
        white-space:break-spaces !important
    }
    .contactRowImage{
        width: auto;
        height: auto;
    }
}
@media only screen and (min-width: 1025px) and (max-width:1280px) {
    .headerFont{
        white-space:break-spaces !important
    }
    .contactRowImage{
        width: auto;
        height: auto;
    }
    .css-prb6n8-MuiPaper-root-MuiCard-root{
        max-width: 100% !important
    }
}
.contactRowText{
    text-align: left;
    padding-left: 50px;
}
.contactRowImage{
    margin-left: 50px;
}
.MuiCardContent-root.card-content.css-46bh2p-MuiCardContent-root{
    height: auto !important;
    text-align: center;
    color: rgba(77, 119, 143, 1);
}
.CardBorderLess{
    box-shadow: none !important;
}
.getInTouchSpan{
    color: rgba(77, 119, 143, 1) !important;
}

@media only screen and (min-width: 1281px) {
    .css-prb6n8-MuiPaper-root-MuiCard-root{
        max-width: 70% !important
    }
    .fontsizeResponsive{
        font-size: 35px;
    }
}
.fontSize33{
    font-size:33px !important
}
.css-o69gx8-MuiCardMedia-root{
    max-width:300px;
    margin: auto;
}