.firstLine {
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    color: rgba(77, 119, 143, 1);
    margin: 10px;
}

.lastLine {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: rgba(77, 119, 143, 1);
    margin-bottom: 45px;
}

.waterMark {
    display: flex;
    justify-content: center;
}

.watermark {
    opacity: 0.4
}

.DivImageOfHandRaise {
    background: url(../Images/HandRaise.png);
    opacity: 0.4;
    position: relative;
    width: 100%;
    min-height: 800px;
    height: 100%;
    background-size: 100% 100%;
}

.GetInvolved {
    opacity: 0.3;
}

.margin20Left {
    margin-left: 20px !important;
}

.margin5Left {
    margin-left: 5px !important;
}

.margin10Top {
    margin-top: 10px !important;
}

.GetInvolved {
    opacity: 0.4;
}

.getInvolved {
    max-height: 100% !important;
    max-width: 95% !important;
    height: 100%;
    width: 100%;
    margin-left: 10px;
}

.starRed {
    color: rgba(77, 119, 143, 1);
}

.css-cveggr-MuiListItemIcon-root {
    min-width: 36px !important
}

ul.MuiList-root.MuiList-padding.css-168pop2-MuiList-root {
    margin-left: 30px !important;
}

.css-168pop2-MuiList-root {
    padding: 0 !important;
    margin: 0 !important;
}

.css-tlelie-MuiListItemText-root {
    flex: none !important
}

.marginleft2 {
    margin-left: 2px;
}

.getInvolveBehalf {
    text-align: center;
}

span.starText {
    font-size: 21px !important;
}

.marginLeft25 {
    margin-left: 25px !important;
}

.handRaiseImage {
    max-width: 50%;
    max-height: 50%;
    float: left;
}

.text {
    font-size: 18px;
    padding-left: 20px;
    padding-top: 20%;
    float: left;
}

.list-group-item {
    background-color: transparent !important;
    border: none !important
}

.fontSize19 {
    font-size: 19px;
}

.padding5Right {
    padding-right: 5px !important;
}

.handRaiseImageOpacity {
    opacity: 0.4;
}

@media only screen and (min-width: 781px) and (max-width: 920px) {
    .DivHandRaise {
        position: absolute;
        top: 25%;
    }

    .paraHandRaise {
        font-size: 25px !important;
    }

    span.starText {
        font-size: 20px !important;
    }

    .list-group {
        padding: 40px;
    }

    .imageBackgroundOfHandRaise {
        background-image: url(../Images/HandRaise.png);
        background-repeat: no-repeat;
    }
}

@media only screen and (min-width: 481px) and (max-width: 780px) {
    .DivHandRaise {
        position: absolute;
        top: 18%;
    }
    
    span.starText {
        font-size: 20px !important;
    }

    .otro-blockquote {
        font-size: 20px !important;
    }
}
@media only screen and (min-width: 320px) and (max-width: 355px) {
    .HandeRaiseImage{
        height: 850px !important;
    }
}
@media only screen and (min-width: 356px) and (max-width: 430px) {
    .HandeRaiseImage{
        height: 750px;
    }
}
@media only screen and (max-width: 480px) {
    .DivHandRaise {
        position: absolute;
        top: 16%;
    }
    .HandeRaiseImage{
        height: 750px;
    }
    
    .imageBackgroundOfHandRaise {
        background-image: url(../Images/HandRaise.png);
        background-repeat: no-repeat;
    }

    .paraHandRaise {
        font-size: 18px !important;
    }

    span.starText {
        font-size: 18px !important;
    }

    .otro-blockquote {
        font-size: 15px !important;
    }
}

.marginTop5Percent {
    margin-top: 5% !important;
}
.spacePaddingTop20{
    padding-top: 20px !important;
}