@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

body {
    width: 100%;
}

ul {
    list-style: none;
    display: flex;
    margin-bottom: 0;
}

.socialMediaIcon {
    max-width: 100%;
    width: 45px !important;
    height: 45px !important;
    vertical-align: bottom;
}

.GoogleImage {
    max-width: 100%;
    width: 45px !important;
    height: 45px !important;
    vertical-align: bottom;
    margin-right: 15px;
}

.social-icon {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    font-size: 20px;
    border-radius: 7px;
    margin-right: 11px;
    cursor: pointer;

}

.social-twitter {
    background-color: #55acee;
}

.social-facebook {
    background-color: #3b5999;
}

.social-linkedin {
    background-color: #0077B5;
}

.social-gmail {
    background-color: #51a9cc;
}

.social-instagram {
    background-color: #dd4b39;
}

.social-icon i {
    transition: 0.4s all;
}

.social-icon img {
    transition: 0.4s all;
}

.social-icon:hover i {
    transform: scale(1.1);
}

.social-icon:hover img {
    transition: 0.4s all;
}

.social-sticky-container {
    padding: 0px;
    margin: 0px;
    position: fixed;
    right: -130px;
    top: 230px;
    width: 210px;
    z-index: 1100;
}

.social-sticky li {
    list-style-type: none;
    background-color: #fff;
    color: #efefef;
    height: 43px;
    padding: 0px;
    margin: 0px 0px 1px 0px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
    cursor: pointer;
}

.social-sticky li:hover {
    margin-left: -115px;
}

.social-sticky li img {
    float: left;
    margin: 5px 4px;
    margin-right: 5px;
}

.social-sticky li p {
    padding-top: 5px;
    margin: 0px;
    line-height: 16px;
    font-size: 11px;
}

.social-sticky li p a {
    text-decoration: none;
    color: #2C3539;
}

.social-sticky li p button {
    text-decoration: none;
    color: #2C3539;
}

.social-sticky li p a:hover {
    text-decoration: underline;
}

.social-sticky li p button:hover {
    text-decoration: underline;
}

.sticky-icon {
    z-index: 1;
    position: fixed;
    top: 30%;
    right: 0%;
    display: flex;
    flex-direction: column;
}

.sticky-icon a {
    transform: translate(110px, 0px);
    border-radius: 50px 0px 0px 50px;
    text-align: left;
    margin: 2px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 8px;
    font-family: 'Oswald', sans-serif;
    transition: all 0.8s;
}

.sticky-icon button {
    transform: translate(160px, 0px);
    border-radius: 50px 0px 0px 50px;
    text-align: left;
    margin: 2px;
    text-decoration: none;
    text-transform: uppercase;
    padding: 5px;
    font-family: 'Oswald', sans-serif;
    transition: all 0.8s;
    border: none !important;
}

.sticky-icon a:hover {
    color: #FFF;
    transform: translate(0px, 0px);
}

.sticky-icon button:hover {
    color: #FFF;
    transform: translate(0px, 0px);
}

.sticky-icon button:hover i {
    transform: rotate(360deg);
}

.sticky-icon a:hover i {
    transform: rotate(360deg);
}

/*.search_icon a:hover i  {
	transform:rotate(360deg);}*/
.Facebook {
    /* background-color: #2C80D3; */
    color: #2C80D3;
}

.Youtube {
    background-color: #fa0910;
    color: #FFF;
}

.Twitter {
    /* background-color: #53c5ff; */
    color: #53c5ff;
}

.Instagram {
    /* background-color: ; */
    color: #FD1D1D;
}

.Google {
    /* background-color: ; */
    color: #61dcf1;
}

.sticky-icon a i {
    background-color: #FFF;
    height: 40px;
    width: 40px;
    color: #000;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    margin-right: 50px;
    transition: all 0.5s;
}

.sticky-icon button img {
    background-color: #FFF;
    height: 40px;
    width: 40px;
    color: #000;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    transition: all 0.5s;
}

.sticky-icon a i.fa-facebook-f {
    background-color: #FFF;
    color: #2C80D3;
}

.sticky-icon a i.fa-instagram {
    background-color: #FFF;
    color: #FD1D1D;
}

.sticky-icon a i.fa-youtube {
    background-color: #FFF;
    color: #fa0910;
}

.sticky-icon a i.fa-twitter {
    background-color: #FFF;
    color: #53c5ff;
}

.sticky-icon button i.fa-envelope-o {
    background-color: #FFF;
    color: #369fd3;
}

.fas fa-shopping-cart {
    background-color: #FFF;
}

#myBtn {
    height: 50px;
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    text-align: center;
    padding: 10px;
    text-align: center;
    line-height: 40px;
    border: none;
    outline: none;
    background-color: #1e88e5;
    color: white;
    cursor: pointer;
    border-radius: 50%;
}

.fa-arrow-circle-up {
    font-size: 30px;
}

#myBtn:hover {
    background-color: #555;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(77, 119, 143, 1);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(77, 119, 143, 1);
    padding-left: 10px;
}

a.nav-link {
    font-weight: 500;
    text-decoration: none;
    font-size: 12px;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(77, 119, 143, 1);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(77, 119, 143, 1);
    padding-left: 10px;
}

.logo {
    height: 80px;
    width: auto;
    margin-left: 5px;
}

@media only screen and (max-width: 480px) {
    .nav-link {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .nav-link {
        font-size: 15px !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width:1160px) {
    .nav-link {
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 1069px) and (max-width:1160px) {
    .nav-link {
        font-size: 18px !important;
    }
}

@media only screen and (min-width: 1161px) and (max-width:1280px) {

    .sticky-icon {
        z-index: 1;
        position: fixed;
        top: 30%;
        right: 0%;
        display: flex;
        flex-direction: column;
    }
}

.gmailFontIcon {
    color: #DB4437 !important;
    background-color: #fff;
}
.fa-facebook{
    color: #2C80D3;
}
.fa-instagram{
    color: #FD1D1D;
}
.fa-twitter{
    color: #53c5ff;
}
.fa-envelope{
    color: #E34234;
}