.headerDefaulter {
    color: rgba(77, 119, 143, 1);
    background: white;
    border: none;
}

.aboutUsAccommodations {
    margin-bottom: 10px;
}

.missionTextAlignmentLeft {
    text-align: left !important;
}

.accommodationMainBody {
    margin-top: 10px;
}

.accomodationcardDiv {
    flex: 1 1 auto;
    min-height: 1px;
    text-align: center;
    float: left;
}

.marginTop10 {
    margin-top: 10px;
    text-align: start;
    margin-right: 10px;
}

.paddlingLeft10 {
    padding-left: 10px;
}

.fa-star {
    color: red;
}

.onlineApplication {
    color: hsl(198deg 77% 53%);
}

.hospitalName {
    text-align: end;
    filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
    overflow: visible;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(77, 119, 143, 1);
}

.btnFindButton {
    width: 50%;
}

.main {
    margin-bottom: 15px;
}

.alignDataGrid {
    margin: 10px;
    margin-bottom: 50px;
}

.selectedHospital {
    text-align: center;
    filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
    overflow: visible;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    color: rgba(77, 119, 143, 1);
}

.fontSize25 {
    font-size: 25px;
}

.datagrid {
    border: none;
    padding-left: 10%;
}

.spinCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.card-header.defaultBackgroundColor {
    margin-bottom: 10px;
    color: rgba(77, 119, 143, 1);
    text-align: start;
    background: white;
    font-size: 40px;
    font-weight: bold;
    text-decoration: underline;
    border: none;
    font-family: Sitka Banner;
}

.MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--sortable {
    height: auto !important;
    width: auto !important;
}

.findAccommodationfirstLine {
    text-align: left;
    font-size: 25px;
    font-weight: bold;
    color: rgba(77, 119, 143, 1);
    margin: 10px;
}

.alignDataGrid {
    height: 500px;
    text-align: center;
}
.hospitalDataGrid{
    margin-bottom: 20px;
}

@media screen and (max-width: 480px) {
    .alignDataGrid {
        height: 500px !important;
        text-align: center;
    }
    .hospitalDataGrid{
        margin-bottom: 50px !important;
    }
    .bulletPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 15px !important;
        color: rgba(77, 119, 143, 1);
    }
}

@media only screen and (min-width: 481px) and (max-width: 720px) {
    .alignDataGrid {
        height: 300px !important;
        text-align: center;
    }
    .hospitalDataGrid{
        margin-bottom: 50px !important
    }
    .bulletPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 15px !important;
        color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
            left: 451px;
            overflow: visible;
            text-align: center;
            font-family: Sitka Banner;
            font-style: normal;
            font-weight: bold;
            font-size: 20px !important;
            color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSizeSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.5rem !important;
        margin-left:15px
    }
    .card-body{
        padding:0px !important
    }
    .pleaseNoteSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.8rem !important;
        margin-left:15px
    }
}

@media only screen and (min-width: 721px) and (max-width: 1024px) {
    .alignDataGrid {
        height: 500px !important;
        text-align: center;
    }
    .hospitalDataGrid{
        margin-bottom: 20px;
    }
    .bulletPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 18px !important;
        color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
            left: 451px;
            overflow: visible;
            text-align: center;
            font-family: Sitka Banner;
            font-style: normal;
            font-weight: bold;
            font-size: 20px !important;
            color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSizeSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.5rem !important;
        margin-left:15px
    }
    .card-body{
        padding:0px !important
    }
    .pleaseNoteSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.8rem !important;
        margin-left:15px
    }
}

@media only screen and (min-width: 1025px) and (max-width:1280px) {
    .alignDataGrid {
        height: 500px !important;
        text-align: center;
    }
    .hospitalDataGrid{
        margin-bottom: 20px;
    }
    .bulletPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
            left: 451px;
            overflow: visible;
            text-align: center;
            font-family: Sitka Banner;
            font-style: normal;
            font-weight: bold;
            font-size: 20px !important;
            color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSizeSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.5rem !important;
        margin-left:15px
    }
    .card-body{
        padding:0px !important
    }
    .pleaseNoteSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.8rem !important;
        margin-left:15px
    }
}

@media only screen and (min-width:1281px) and (max-width:1532px) {
    .alignDataGrid {
        height: 500px !important;
        text-align: center;
    }
    .hospitalDataGrid{
        margin-bottom: 20px;
    }
    .bulletPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 25px !important;
        color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
            left: 451px;
            overflow: visible;
            text-align: center;
            font-family: Sitka Banner;
            font-style: normal;
            font-weight: bold;
            font-size: 20px !important;
            color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSizeSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.5rem !important;
        margin-left:15px
    }
    .card-body{
        padding:0px !important
    }
    .pleaseNoteSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.8rem !important;
        margin-left:15px
    }
}

@media only screen and (min-width:1533px) {
    .alignDataGrid {
        height: 500px !important;
        text-align: center;
    }
    .hospitalDataGrid{
        margin-bottom: 20px;
    }
    .bulletPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 25px !important;
        color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSize{
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
            left: 451px;
            overflow: visible;
            text-align: center;
            font-family: Sitka Banner;
            font-style: normal;
            font-weight: bold;
            font-size: 20px !important;
            color: rgba(77, 119, 143, 1);
    }
    .dashedPointFontSizeSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.5rem !important;
        margin-left:15px
    }
    .card-body{
        padding:0px !important
    }
    .pleaseNoteSpan{
        letter-spacing:.05em !important;
        color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
        --tw-text-opacity:1 !important;
        font-size:1.8rem !important;
        margin-left:15px
    }
}
.ApplyAccommodation{
    background-color:rgba(77, 119, 143, 1);
    height: 350px;
    border-radius: 30%;
}
.applyAccommodationSpan{
    color: #fff;
}
.accommodationRequirement{
    margin-top: 20px;
}
.findAccommodationHospital{
    text-align: left;
    font-size: 25px;
    font-weight: bold;
    color: rgba(77, 119, 143, 1);
    margin: 10px;
}
.findAccommodationDiv{
    text-align: right;
    background-color: #fff;
}
.hotelsTable{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    border: 1px solid rgba(77, 119, 143, 1);
}
.width30{
    width: 30%;
}
.width100{
    width: 100%;
}
tbody{
    color: rgba(77, 119, 143, 1);
    background-color: #fff;
    font-size: 20px;
}
tbody:hover{
    background-color: white;
}
thead{
    color: rgba(77, 119, 143, 1);
}
.card{
    background-color: #fff;
}
.card-footer{
    background-color: #fff;
    border: #fff;
}

#exTab1 .tab-content {
    color : white;
    background-color: #428bca;
    padding : 5px 15px;
  }
  
  #exTab2 h3 {
    color : white;
    background-color: #428bca;
    padding : 5px 15px;
  }
  
  /* remove border radius for the tab */
  
  #exTab1 .nav-pills > li > a {
    border-radius: 0;
  }
  
  /* change border radius for the tab , apply corners on top*/
  
  #exTab3 .nav-pills > li > a {
    border-radius: 4px 4px 0 0 ;
  }
  
  #exTab3 .tab-content {
    color : white;
    background-color: #428bca;
    padding : 5px 15px;
  }
  
  .errorSpan{
    color: red;
    font-size: 20px;
    font-weight: bold;
  }
  .BlockError{
    display: block;
    text-align: center;
  }
  .NoneError{
    display: none;
  }
  .AccommodationSubSpan{
    letter-spacing:.05em !important;
    color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity:1 !important;
    font-size:2.5rem !important;
    margin-left:15px
}
.verticalAlignBaseLine{
    vertical-align: baseline;
}
.defaultBackgroundColor{
    color:  rgba(77, 119, 143, 1);
}
.AccommodationMainSpan{
    letter-spacing:.05em !important;
    color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity:1 !important;
    font-size:2.7rem !important;
    margin-left:15px
}