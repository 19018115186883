@import url(./stylesheets/about-us.css);
@import url(./stylesheets/accommodation.css);
@import url(./stylesheets/contact-us.css);
@import url(./stylesheets/donation.css);
@import url(./stylesheets/events.css);
@import url(./stylesheets/footer.css);
@import url(./stylesheets/get-involved.css);
@import url(./stylesheets/home.css);
@import url(./stylesheets/home-mobile-resolution.css);
@import url(./stylesheets/navbar.css);
@import url(./stylesheets/quotes.css);
@import url(./stylesheets/social-media.css);
@import url(./stylesheets/what-we-do.css);
@import url(./stylesheets/page-not-found.css);
@import url(./stylesheets/sponsor.css);

html,body{
    overflow-x: hidden;
}