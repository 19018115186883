.mainSponsor{
    background-image: url(../Images/sponsor.jpg);
}
.uppercase{
    text-transform: capitalize;
}
.underlined{
    text-decoration: underline;
}
.anchor{
    color: #036;
}
.TaxDiv{
    text-align: center !important;
}
@media only screen and (min-width: 1025px)
{
    .image-hero {
        align-items: center;
        background-image: url(../Images/sponsor.jpg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        flex-direction: row;
    }
}
.image-hero {
    align-items: flex-start;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 0;
}

@media only screen and (min-width: 1025px)
{
    .image-hero-qJGki4uNa8MqtyrG {
        background-image: url(../Images/sponsor.jpg) !important;
    }
}
@media (min-width: 1025px)
{
    .image-hero {
        min-height: 1072px;
        align-items: flex-start;
    }
}

@media (min-width: 1025px)
{
    .wps-rebrand .image-hero {
        background-image: url(../Images/sponsor.jpg) !important;
    }
}
@media (min-width: 980px)
{
    .image-hero .large-6:first-of-type {
        width: 525px !important;
    }
}
.marginLeft50{
    margin-left: 50px;
}
.spanSponsor{
    font-size: 48px;
}
.pSponsor{
    text-align: center;
}

.backgroundColorSponsorImage{
    background-color: antiquewhite;
    margin-left: 80px;
}
.bg-cw-wps-blue {
    background-color: #E5E9E9
}
.pb-2 {
    padding-bottom: 20px!important;
}
.py-5 {
    padding-top: 80px!important;
    padding-bottom: 80px!important;
}
.text-cw-wps-navy {
    color: #32446b;
}
.max-w-mdx {
    max-width: 700px !important;
}
@media (min-width: 641px)
{
    .md-mx-auto {
        margin-left: auto!important;
        margin-right: auto!important;
    }
}
.text-center {
    text-align: center!important;
}
@media only screen and (min-width: 1025px)
{
    .h35 {
        font-size: 35px;
    }
}
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
.font-normal {
    font-weight: 400!important;
}
.font-heading {
    font-family: Kazimir,ui-serif,Georgia,Cambria,Times New Roman,Times,serif!important;
}
.p35 {
    font-size: 18px !important;
}
.mt-2 {
    margin-top: 20px!important;
}
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
.text-cw-wps-navy {
    color: #32446b;
}
@media (min-width: 1025px)
{
    .lg-px-5 {
        padding-left: 80px!important;
        padding-right: 80px!important;
    }
}

@media (min-width: 641px)
{
    .md-mx-auto {
        margin-left: auto!important;
        margin-right: auto!important;
    }
}

.mt-3 {
    margin-top: 40px!important;
}
.items-start {
    align-items: flex-start!important;
}
@media (min-width: 1025px)
{
    .lg-block {
        display: block!important;
    }
}
.max-w-sm {
    max-width: 600px!important;
}.flex {
    display: flex!important;
}
.wps-rebrand .number-milestone--first {
    position: relative;
    display: block;
}
@media only screen and (min-width: 1025px)
{
    .wps-rebrand .number-milestone--first:before {
        width: calc(50% - 20px);
        height: 1.5px;
        left: calc(50% + 20px);
        top: 20px;
    }
}
.wps-rebrand .number-milestone--first:before {
    content: "";
    display: block;
    position: absolute;
    width: 1.5px;
    height: calc(100%);
    top: 40px;
    left: 20px;
    background-color: #C1C8D0;
}
.wps-rebrand .icon-block .number {
    background: #FEFBF5;
    border: solid 1.5px #32446B;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    text-align: center;
    padding-top: 8px;
    font-weight: 400;
    margin: auto;
    font-size: 19px;
    font-weight: 500;
}
@media (min-width: 1025px)
{.lg-mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}}
.lg-mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
@media (min-width: 1025px)
{
    .lg-mt-2 {
        margin-top: 20px!important;
    }
}
.h60 {
    font-size: 19px;
}
.wps-rebrand .number-milestone--first {
    position: relative;
    display: block;
}
@media only screen and (min-width: 1025px)
{.wps-rebrand .number-milestone--first:before {
    width: calc(50% - 20px);
    height: 1.5px;
    left: calc(50% + 20px);
    top: 20px;
}}
.wps-rebrand .number-milestone--first:before {
    content: "";
    display: block;
    position: absolute;
    width: 1.5px;
    height: calc(100%);
    top: 40px;
    left: 20px;
    background-color: #C1C8D0;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
    font-size: 40px !important;
    color: rgba(77, 119, 143, 1) !important;
}
.css-117w1su-MuiStepIcon-text{
    fill: rgb(255, 255, 255);
    font-size: 0.75rem;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}
circle{
    border: solid 1.5px #32446B;
}
.css-zpcwqm-MuiStepConnector-root{
    background-color: #C1C8D0 !important;
}
.fontSize16{
    font-size: 16px;
}
.css-2fdkz6{
    font-size: 20px;
    color: rgba(77, 119, 143, 1);
}
.css-4ff9q7{
    font-size: 40px !important;
    color: rgba(77, 119, 143, 1) !important;
}
.css-1abj2s5{
    font-size: 23px !important;
}
.css-15oeqyl{
    top: 20px !important;
}
.column {
    float: left;
    width: 33.33%;
    padding:10px
  }
  
  /* Stops the float property from affecting content after the columns */
  .columns:after {
    content: "";
    display: table;
    clear: both;
  }
  @media screen and (max-width:768px){
    .column{
        width:100%
    }
    .css-1hv8oq8-MuiStepLabel-label{
        font-size: 0.75rem !important;
    }
  }

  .image-hero-qJGki4uNa8MqtyrG{
    background-image: url(../Images/sponsor.jpg) !important;
    width: auto;
    height: auto;
  }
  .marginAuto{
    margin: auto !important;
  }
  .labelText{
    font-size: 15px !important;
  }
  #labelText{
    font-size: 15px !important;
  }
  .spanSponsor46Px{
    font-size:46px
  }
