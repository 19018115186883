.blockquote {
    padding: 60px 80px 40px;
    position: relative;
}
.blockquote p {
    font-family: "Utopia-italic";
    font-size: 35px;
    font-weight: 700px;
    text-align: center;
}

.blockquote:before {
  position: absolute;
  font-family: 'FontAwesome';
  top: 0;
  
  content:"\f10d";
  font-size: 200px;
  color: rgba(0,0,0,0.1);
   
}

.blockquote::after {
    content: "";
    top: 20px;
    left: 50%;
    margin-left: -100px;
    position: absolute;
    border-bottom: 3px solid #bf0024;
    height: 3px;
    width: 200px;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:400italic);
.otro-blockquote{
  font-size: 1.2em;
  width:80%;
  margin:30px auto;
  font-family:Open Sans;
  font-style:italic;
  color: #555555;
  line-height:1.6;
  position: relative;
  font-weight: bold;
}

.otro-blockquote::before{
  font-family:Arial;
  color:#78C0A8;
  font-size:4em;
  position: absolute;
  left: 10px;
  top:-10px;
}

.otro-blockquote::after{
  content: '';
}

.otro-blockquote span{
  display:block;
  color:#333333;
  font-style: normal;
  font-weight: bold;
  text-align: right;
}

@media screen and (max-width: 480px) {
	.otro-blockquote{
		width: 100% !important;
		margin:none !important;
	}
}

@media only screen and (min-width: 481px) and (max-width: 720px) {
	.otro-blockquote{
		width: 100% !important;
		margin:none !important;
	}
}
@media only screen and (min-width: 721px) and (max-width: 1024px) {
	.otro-blockquote{
		width: 100% !important;
		margin:none !important;
	}
}