.defaultFont{
    filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
    overflow: visible;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    color: rgba(77,119,143,1);
}
.donationMainSpan{
    text-align:center;
    font-size: 38px;
}
.donationSubMainSpan{
    text-align:center;
    font-size: 25px;
}
.paymentForm{
    height: 100%;
    width: 100%;
}
.paymentIframe{
    width: 100%;
    height: 100%;
    margin-top: 30px;
}
iframe{
    background-color: transparent;

}
.mainDonation{
    text-align: center;
    margin-top: 22px !important;
    
}
.subMainDonation{
    text-align: start;
    margin-top: 40px;
}
.SincerelyText{
    margin-top: 75px !important;
}

/* .jotForm {
    width: 100%;
    height: auto;
} */
.applicationForm{
    width: 100%;
    height: 100vh;
}
.displaySpinDonateCenter{
    display: flex;
    justify-content: center;
     height: 750px;
     margin:auto !important
}
.formatLeft{
    text-align: left;
}
.marginBottom50{
    margin-bottom: 50px;
}
@media only screen and (max-width: 480px) {
    .spinLeft{
        top: 15%;
        position: absolute;
    }
    .donationMainSpan{
        text-align:center;
        font-size: 35px;
    }
    .subMainDonation{
        margin-top: 20px;
        font-size: 20px;
    }
    .donationSubMainSpan{
        margin-top: 30px;
        font-size: 20px;
    }
    .applicationForm{
        width: 100%;
        height: 100vh;
    }
}

@media only screen and (min-width: 481px) and (max-width: 1024px) {
    .spinLeft{
        top: 15%;
        position: absolute;
    }
    .donationMainSpan{
        text-align:center;
        font-size: 30px;
    }
    .subMainDonation{
        margin-top: 20px;
        font-size: 20px;
    }
    .donationSubMainSpan{
        margin-top: 30px;
        font-size: 20px;
    }
    .applicationForm{
        width: 100%;
        height: 100vh;
    }
    /* .jotForm{
        max-width:380px !important;
        width: 100%;
    } */
    .margin80Left{
        margin-left: 0 !important;
    }
    .marginLeft50{
        margin-left: 20px !important;
    }
    .marginRight50{
        margin-right: 20px !important;
    }
    .css-prb6n8-MuiPaper-root-MuiCard-root{
        max-width:800px !important;
        width: 100% !important;
    }
    .css-186h6x3-MuiCardMedia-root{
        height:264px !important
    }
}

@media only screen and (min-width: 1025px) and (max-width:1280px) {
    .spinLeft{
        top: 15%;
        position: absolute;
    }
    .donationMainSpan{
        text-align:center;
        font-size: 35px;
    }
    .subMainDonation{
        margin-top: 20px;
        font-size: 20px;
    }
    .donationSubMainSpan{
        margin-top: 30px;
        font-size: 28px !important;
    }
    .applicationForm{
        width: 100%;
        height: 100vh;
    }
}

#donateIframe{
    height: 650px;
}
.donate{
    background-color: #ebeb13;
}
.fontRed{
    color: red;
}
.margin80Left{
    margin-left: 80px;
}
.margin80Top{
    margin-top: 80px !important;
}
.quoteAuthor{
    text-align: right !important;
    margin-right: 100px;
}
.donationQuote{
    text-align:center;
    font-size: 1.5em !important;
}

span.h10.hero--header{
    color: rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity: 1 !important;
}
h1.h10.hero--header{
    color: rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity: 1 !important;
    font-family:Sitka Banner;
    font-size: 55px !important;
    text-decoration: underline;
}
#contain {
    
    height:100%;/* no need  */
    margin:0;
    padding:0;
    text-align:left;
}
#img {
    padding:0;
    margin:0;
    width:49%;
    min-width:350px;   
    vertical-align:bottom;
}

.donationBox{
    margin-top: -30px;
}
.boxColorYellow{
    color:#cbac2f !important;
    /* font-weight: bold */
}
.margin23Top{
    margin-top: 23px !important;
}
span.handTextSpan {
    font-size: 30px;
    color: black;
    font-weight: 100;
}
.fontFamilySegoe{
    font-family: Segoe Print !important;
}
@media only screen and (min-width: 481px) and (max-width: 576px) {
    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 650px !important;
        margin: auto !important;
        max-width: 450px;
    }
}
.suggested-amount-box{
    width: 28% !important;
}