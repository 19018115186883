.btn-outline-light {
    border: none !important;
}

.Uniting_Families_So_They_Can_B {
    filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
    -webkit-filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    color: rgba(252, 253, 253, 1);
}

.companyName {
    font-size: 25px !important;
    text-align: left !important;
}

.footer-heading {
    font-size: 15px;
    color: #aaa;
    display: block;
    padding: 0;
    padding-bottom: 10px !important;
}

.ulDisplay {
    display: table !important;
}

.textUnderLineNone {
    text-decoration: none
}

b.WebsiteText {
    font-size: 15px !important;
}

.backgroundColorOfEmail {
    color: yellow;
}

.emailBackGroundColor {
    color: white !important;
}

@media only screen and (min-width: 1281px) {
    h6.text-uppercase.mb-4.font-weight-bold.footer-heading {
        font-size: 20px !important;
    }
    .linkFooter{
        font-size: 19px !important;
    }
}
