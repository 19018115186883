@media only screen and (max-width: 213px){
    .displaySpinDonateCenter {
        height: 1080px !important;
    }
}
@media only screen and (min-width: 213px) and (max-width: 218px){
    .displaySpinDonateCenter {
        height: 1000px !important;
    }
}
@media only screen and (min-width: 218px) and (max-width: 235px) {
    .mobileVersion{
        margin-top: 20px !important;
    }
    .displaySpinDonateCenter {
        height: 960px !important;
    }
}
@media only screen and (min-width: 235px) and (max-width: 250px){
    .displaySpinDonateCenter {
        height: 940px !important;
    }
}
@media only screen and (min-width: 251px) and (max-width: 270px){
    .displaySpinDonateCenter {
        height: 920px!important; 
    }
}
@media only screen and (min-width: 270px) and (max-width: 280px) {
    .displaySpinDonateCenter {
        height: 900px!important; 
    }
}
@media only screen and (min-width: 406px) and (max-width: 480px) {
    .displaySpinDonateCenter {
        height: 830px !important;
    }
    .jotForm.displaySpinCenter{
        height: 650px !important;
    }

    .fontsizeResponsiveContactUs {
        font-size: 20px !important;
    }

    span.handTextSpan {
        font-size: 26px !important;
        color: black;
        font-weight: 100;
    }

    .helpusText {
        --tw-text-opacity: 1 !important;
        font-family: Sitka Banner;
        font-size: 32px
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
    .imageBody{
        position: relative;
        width: 100%;
        min-height: 500px;
        height: 100%;
        background-size: 100% 100%;
    }
    .newsImage{
        height: 100%;
        width: 100%;
        border-radius: 20px;
        margin: 10px;
    }
}
.mobileVersion{
    margin-top: 50px;
}
@media screen and (max-width: 480px) {
    .mobilePadding {
        padding: 10px !important;
    }
    .jotForm.displaySpinCenter{
        height: 650px !important;
    }


    .fontsizeResponsiveContactUs {
        font-size: 22px !important;
    }

    .ShelteringSpan {
        font-size: 1.75rem !important;
    }

    .helpusText {
        --tw-text-opacity: 1 !important;
        font-family: Sitka Banner;
        font-size: 32px
    }

    span.handTextSpan {
        font-size: 26px !important;
        color: black;
        font-weight: 100;
    }

    .rounded {
        padding: 10px;
    }

    .css-o69gx8-MuiCardMedia-root {
        max-width: 250px;
        margin: auto;
    }

    .css-2fdkz6 {
        font-size: 0.75 rem;
    }

    .spanSponsor46Px {
        font-size: 40px !important;
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100% !important;
        width: 100%;
        object-fit: contain;
        padding: 10px;
    }

    .header {
        text-align: center;
        font-size: 15px;
    }

    .fontsizeResponsive {
        font-size: 23px !important;
    }

    .marginLeft20 {
        margin: 20px !important;
    }

    .ShelteringSpan {
        font-size: 1.75rem !important;
    }

    .headerPara {
        font-size: 13px;
        height: 120px !important;
        overflow: hidden;
    }

    img.sketchImage {
        height: 700px;
        width: 700px;
    }

    .HospitalImage {
        padding: 15px;
    }

    .heroHeader {
        /* width: 604px !important; */
        text-align: center;
        color: rgba(77, 119, 143, 1);
        font-weight: 550;
        font-family: "Times New Roman", Times, serif !important;
    }

    .ImageSection {
        height: 700px;
        width: 700px;
    }

    .displayWaterMarkPhoto {
        width: 100%;
        height: 300px;
        opacity: 0.3;
        transition: -webkit-transform .5s ease;
        transition: transform .5s ease;
        transition: transform .5s ease, -webkit-transform .5s ease;
    }

    .fontSize20 {
        font-size: 15px !important;
    }

    .width70percent {
        width: 95% !important;
    }

    .fontSize35 {
        font-size: 20px !important;
    }

    .Uniting_Families_So_They_Can_B {
        filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
        -webkit-filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
    }

    .missionText {
        -webkit-filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 19px !important;
        color: rgba(77, 119, 143, 1)
    }

    .column {
        width: 480px;
        padding: 10px;
        margin: 0;
        left: 0;
        text-align: center;
        overflow: hidden;
    }

    .Uniting_Families {
        position: relative;
        font-size: 25px;
        position: absolute;
        top: 3%;
        width: 100%;
        text-align: center;
    }

    .ImageSection {
        height: 700px;
        width: 700px;
    }

    .sketchImage {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
    }

    .backgroundsecondHeader {
        font-size: 15px !important;
        position: absolute;
        font-weight: normal;
        top: 5%;
        width: 100%;
        text-align: center;
    }

    .displayWaterMarkImage {
        padding: 5px;
    }

    .headerFont {
        font-size: 25px;
    }

    .alignTextCenter {
        font-size: 20px;
    }

    .defaultbackgroundColor {
        color: rgba(77, 119, 143, 1);
    }

    .defaultbackgroundColor {
        color: rgba(77, 119, 143, 1);
    }

    .defaultHeaderColor {
        background: none !important;
    }

    .secondHeader {
        max-width: 100%;
    }

    .mt-2 {
        line-height: 1.3em;
        font-size: 15px;
        margin-top: 0 !important;
    }

    .centeredImagine {
        left: 45% !important;
        text-align: center
    }

    .postionOfSpanHelpUs {
        top: 25%;
    }

    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 750px;
        max-width: 480px !important;
        width: 100% !important;
    }

    .suggested-amount-box.amount {
        width: 28% !important;
    }

    .displaySpinCenterMarginTop {
        margin: auto !important;
        margin-top: 0px !important;
        padding: 10px;
    }

    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 830px;
        margin: auto !important;
        max-width: 450px;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 10px !important;
    }

    img.hotelImages {
        border-radius: 25px;
        max-height: 400px;
        max-width: 550px;
        width: 100%;
        height: 100%;
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
    .newsImage{
        height: 100%;
        width: 100%;
        border-radius: 20px;
        margin: 0 auto;
    }
}
@media only screen and (min-width: 321px) and (max-width: 406px) {
    .DivHandRaise {
        position: absolute;
        top: 18% !important;
    }
    .displaySpinDonateCenter{
        height: 830px !important;
        margin-bottom: 20px !important;
    }

    .HandeRaiseImage {
        height: 850px !important;
    }

    .fontsizeResponsiveContactUs {
        font-size: 20px !important;
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 20px !important;
    }
    .newsImage{
        height: 100%;
        width: 100%;
        border-radius: 20px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 279px) and (max-width: 283px){
    .displaySpinDonateCenter {
        height: 890px !important;
        margin-bottom: 30px !important;
        /* width: 450px !important; */
    }
}
@media only screen and (min-width: 283px) and (max-width:284px){
    .displaySpinDonateCenter {
        height: 865px !important;
        margin-bottom: 30px !important;
        /* width: 450px !important; */
    }
}
@media only screen and (min-width: 284px) and (max-width: 321px) {
    .displaySpinDonateCenter {
        height: 835px !important;
        margin-bottom: 30px !important;
        /* width: 450px !important; */
    }
    .DivHandRaise {
        position: absolute;
        top: 19% !important;
    }

    .fontSize19 {
        font-size: 17px !important;
    }

    .HandeRaiseImage {
        height: 800px !important;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 23px !important;
    }
    .newsImage{
        height: 100%;
        width: 100%;
        border-radius: 20px;
        margin: 10px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 656px) {
    .HandeRaiseImage {
        height: 700px;
    }

    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 790px !important;
        margin: auto !important;
        margin-bottom: 20px !important;
        width: 450px !important;
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100% !important;
        width: 50%;
    }

    .ShelteringSpan {
        font-size: 1.75rem !important;
    }

    .cardHeight {
        height: 620px;
    }

    img.hotelImages {
        height: 100%;
    }

    img.hotelImages {
        height: 100%;
        border-radius: 25px;
    }

    .fontsizeResponsiveContactUs {
        font-size: 25px !important;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 15px !important;
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 20px !important;
    }
    .newsImage{
        height: 400px;
        width: 450px;
        border-radius: 20px;
        margin: 0 auto;
    }
    .TaxDiv{
        padding-right: 20px;
    }
}

@media only screen and (min-width: 656px) and (max-width: 680px) {
    .fontsizeResponsiveContactUs {
        font-size: 28px !important;
    }
    .TaxDiv{
        text-align: center !important;
        /* margin-left: 0px !important; */
    }
    .aboutUsFontSize {
        font-size: 19px !important;
    }

    .donationMainSpan {
        font-size: 20px !important;
    }

    img.hotelImages {
        height: 100%;
        border-radius: 25px;
    }

    .HandeRaiseImage {
        height: 630px;
    }

    span.handTextSpan {
        font-size: 28px !important;
        color: black;
        font-weight: 100;
    }

    .ImageNewIcon {
        width: 500px !important;
    }

    .rounded {
        padding: 10px;
    }

    .css-o69gx8-MuiCardMedia-root {
        max-width: 250px;
        margin: auto;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 10px !important;
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100% !important;
        width: 80%
    }

    .mobilePadding {
        padding: 10px !important;
    }

    .HospitalImage {
        padding: 15px;
    }

    span.handTextSpan {
        font-size: 18px !important;
    }

    .ShelteringSpan {
        font-size: 2rem !important;
    }

    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 650px;
        max-width: 550px;
    }

    .displaySpinCenterMarginTop {
        margin: auto !important;
        margin-top: 0px !important;
        padding: 10px;
    }

    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 775px!important;
        margin: auto !important;
        margin-bottom: 30px !important;
        width: 450px;
    }

    img.hotelImages {
        border-radius: 25px;
        max-height: 400px;
        max-width: 550px;
        width: 100%;
        height: 100%;
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 20px !important;
    }
    .newsImage {
        height: 420px;
        width: 520px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 767px) and (max-width: 780px) {
    .HandeRaiseImage {
        height: 600px !important;
    }
    
    .aboutUsFontSize {
        font-size: 19px !important;
    }

    .fontsizeResponsiveContactUs {
        font-size: 30px !important;
    }

    .DivHandRaise {
        position: absolute;
        top: 25% !important;
    }

    img.hotelImages {
        height: 100%;
        border-radius: 25px;
    }

    .fontText {
        font-size: 17px !important;
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
    .newsImage {
        height: 420px;
        width: 520px;
        margin: 0 auto;
    }
    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 710px !important;
        margin: auto !important;
        max-width: 450px;
    }
}

@media only screen and (min-width: 772px) and (max-width: 780px) {
    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 660px !important;
        margin: auto !important;
        max-width: 450px;
    }
    .fontsizeResponsiveContactUs {
        font-size: 22px !important;
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
    .newsImage {
        height: 420px;
        width: 520px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 769px) and (max-width: 781px) {
    .fontsizeResponsiveContactUs {
        font-size: 18px !important;
    }
    .TaxDiv{
        text-align: center !important;
        margin-right: 50px !important;
    }
    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
    .newsImage {
        height: 420px;
        width: 520px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 681px) and (max-width: 780px) {
    .TaxDiv{
        text-align: center !important;
        /* margin-left: 10px !important; */
    }
    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }

    .fontText {
        font-size: 17px;
    }

    .HandeRaiseImage {
        height: 600px;
    }

    img.left-img.ImageNewIcon.img-responsive {
        height: 500px;
        width: 650px;
    }

    .css-o69gx8-MuiCardMedia-root {
        max-width: 250px;
        margin: auto;
    }

    .donationMainSpan {
        font-size: 25px !important;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 10px !important;
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100% !important;
        width: 50%;
    }

    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 650px;
        max-width: 550px;
    }

    .displaySpinCenterMarginTop {
        margin: auto !important;
        margin-top: 0px !important;
        padding: 0px;
    }

    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 780px !important;    
        margin: auto !important;
        width: 450px;
        margin-bottom: 20px !important;
    }

    .HospitalImage {
        padding: 15px;
    }

    .mobilePadding {
        padding: 10px !important;
    }

    .ShelteringSpan {
        font-size: 2rem !important;
    }

    span.handTextSpan {
        font-size: 20px !important;
    }

    .header {
        text-align: center;
        font-size: 20px;
    }

    .fontsizeResponsive {
        font-size: 20px !important;
    }

    .headerPara {
        font-size: 16px;
        height: 120px !important;
    }

    .fontSize35 {
        font-size: 25px !important;
    }

    .heroHeader {
        width: '100%' !important;
        text-align: center;
        color: rgba(77, 119, 143, 1);
        font-weight: 550;
        font-family: "Times New Roman", Times, serif !important;
    }

    .postionOfParaAccommodation {
        top: 64% !important
    }

    .postionOfParaDonation {
        top: 62% !important
    }

    .width70percent {
        width: 95% !important;
    }

    .Uniting_Families_So_They_Can_B {
        filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
        -webkit-filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: normal;
    }

    .missionText {
        -webkit-filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        color: rgba(77, 119, 143, 1)
    }

    .fontWeightBold {
        font-weight: bold !important;
    }

    .headerFont {
        font-size: 25px;
    }

    .alignTextCenter {
        font-size: 20px;
    }

    .defaultbackgroundColor {
        color: rgba(77, 119, 143, 1);
    }

    .defaultHeaderColor {
        background: none !important;
    }

    .sketchImage {
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
    }

    .secondHeader {
        max-width: 100%;
    }

    .Uniting_Families {
        position: relative;
        font-size: 25px;
        position: absolute;
        top: 3%;
        width: 100%;
        text-align: center;
    }

    .backgroundsecondHeader {
        font-size: 15px !important;
        position: absolute;
        font-weight: normal;
        top: 5%;
        width: 100%;
        text-align: center;
    }

    .fontSize35 {
        font-size: 25px !important;
    }

    .postionOfSpanAccommodation {
        top: 21%;
    }

    .mt-2 {
        line-height: 1.3em;
        font-size: 15px;
        margin-top: 0 !important;
    }

    .centeredImagine {
        left: 45% !important;
        text-align: center
    }

    .postionOfSpanHelpUs {
        top: 25%;
    }

    img.hotelImages {
        border-radius: 25px;
        max-height: 400px;
        max-width: 550px;
        width: 100%;
        height: 100%;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
}

@media only screen and (min-width: 781px) and (max-width: 920px) {
    .taxDedution {
        color: #4d778f;
        font-size: 14px !important;
    }

    .aboutUsFontSize {
        font-size: 19px !important;
    }

    .fontText {
        font-size: 19px !important;
    }

    .fontSize24 {
        font-size: 18px !important;
    }

    .HandeRaiseImage {
        height: 600px !important;
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        height: 100% !important;
        width: 50%;
    }

    .headerPara {
        font-size: 16px;
        height: 100px !important;
        overflow: hidden;
    }

    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 650px;
        max-width: 550px;
    }

    .displaySpinCenterMarginTop {
        margin: auto !important;
        margin-top: 0px !important;
    }

    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 790px !important;
        margin: auto !important;
        width: 550px !important;
        margin-bottom: 20px !important;
    }

    .HospitalImage {
        padding: 15px;
    }

    .mobilePadding {
        padding: 10px !important;
    }

    .donationMainSpan {
        font-size: 25px !important;
    }

    span.handTextSpan {
        font-size: 22px !important;
    }

    .ShelteringSpan {
        font-size: 2.3rem !important;
    }

    .heroHeader {
        width: 60 !important;
        text-align: center;
        color: rgba(77, 119, 143, 1);
        font-weight: 550;
        font-family: "Times New Roman", Times, serif !important;
    }

    .centeredImagine {
        left: 45% !important;
        text-align: center
    }

    .postionOfSpanHelpUs {
        top: 25%;
    }

    .header {
        text-align: center;
        font-size: 23px;
    }

    img.hotelImages {
        border-radius: 25px;
        max-height: 400px;
        max-width: 550px;
        width: 100%;
        height: 100%;
    }

    .fontResponsive {
        font-size: 21px !important;
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
}

@media only screen and (min-width: 921px) and (max-width: 1024px) {
    .donationMainSpan {
        font-size: 25px !important;
    }
    
    .displaySpinDonateCenter{
        display: flex;
        justify-content: center;
        height: 810px !important;
        margin: auto !important;
        width: 550px !important;
    }
    .HandeRaiseImage {
        height: 500px !important;
        width: 100% !important;
    }

    .nextFontSize {
        font-size: 20px !important
    }

    .imageBackgroundOfHandRaise {
        background-image: url(../Images/HandRaise.png);
        background-repeat: no-repeat;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 14px !important;
        padding-right: 30px;
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50% !important;
        height: 100% !important;
    }

    .headerPara {
        font-size: 16px;
        height: 90px !important;
        overflow: hidden;
    }

    .HospitalImage {
        padding: 15px;
    }

    img.hotelImages {
        border-radius: 25px;
        max-height: 400px;
        max-width: 550px;
        width: 100%;
        height: 100%;
    }

    .mobilePadding {
        padding: 10px !important;
    }

    .ShelteringSpan {
        font-size: 2.5rem !important;
    }

    span.handTextSpan {
        font-size: 23px !important;
    }

    .DivHandRaise {
        position: absolute;
        top: 25%;
    }

    .header {
        text-align: center;
        font-size: 23px;
    }

    .heroHeader {
        text-align: center;
        color: rgba(77, 119, 143, 1);
        font-weight: 550;
        font-family: "Times New Roman", Times, serif !important;
    }

    .Uniting_Families_So_They_Can_B {
        filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
        -webkit-filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: normal;
    }

    .width70percent {
        width: 95% !important;
    }

    img.sketchImage {
        height: 100%;
        width: 100%;
    }

    .missionText {
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        color: rgba(77, 119, 143, 1);
    }

    .headerFont {
        font-size: 25px;
    }

    .alignTextCenter {
        font-size: 20px;
    }

    .ImageSection {
        height: 100%;
        width: 100%;
    }

    .defaultbackgroundColor {
        color: rgba(77, 119, 143, 1);
    }

    .defaultHeaderColor {
        background: none !important;
    }

    .secondHeader {
        max-width: 60%;
    }

    .fontSize35 {
        font-size: 30px !important;
    }

    .mt-2 {
        line-height: 1.3em;
        font-size: 15px;
        margin-top: 0 !important;
    }

    .fontSize24 {
        font-size: 20px !important;
    }

    .postionOfSpanHelpUs {
        top: 16%;
    }

    .postionOfParaHelpUs {
        top: 52% !important;
    }

    .centeredImagine {
        left: 49.5% !important;
        text-align: center;
    }

    img.hotelImages {
        border-radius: 25px;
        max-height: 400px;
        max-width: 550px;
        width: 100%;
        height: 100%;
    }

    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 650px;
        width: 550px;
    }

    .displaySpinCenterMarginTop {
        margin: auto !important;
        margin-top: 0px !important;
        padding: 20px;
    }

    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        height: 650px;
        margin: auto !important;
        max-width: 450px;
    }

    .fontResponsive {
        font-size: 21px !important;
    }

    img.imageDisplay {
        height: auto !important;
        width: 100%;
        border-radius: 50px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width:1075px) {
    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 650px;
        width: 450px;
    }

    .margin80Left {
        margin-left: 0px !important;
    }

    .heroText {
        padding-left: 70px !important;
    }
    .displaySpinDonateCenter{
        height: 730px !important;
    }
    .taxDedution {
        color: #4d778f;
        font-size: 14px !important;
    }

    .fontResponsive {
        font-size: 21px !important;
    }

    .DivHandRaise {
        position: absolute;
        top: 27%;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
    .SponserP{
        margin-left: 40px !important;
    }
}

@media only screen and (min-width: 1075px) and (max-width:1280px) {
    .SponserP{
        margin-left: 50px !important;
    }
    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 650px;
        width: 450px;
    }
    .displaySpinDonateCenter{
        height: 730px !important;
    }

    .fontResponsive {
        font-size: 21px !important;
    }

    .DivHandRaise {
        position: absolute;
        top: 27% !important;
    }

    #contain {
        padding: 10px;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 14px !important;
    }

    .margin80Left {
        margin-left: 0px !important;
    }

    .HandeRaiseImage {
        height: 500px !important;
        width: 100% !important;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }

}

@media only screen and (min-width: 1025px) and (max-width:1280px) {
    .ImageSection {
        max-width: 100%;
        max-height: 100%;
    }
    .displaySpinDonateCenter{
        height: 810px !important;
    }
    .TaxDiv {
        text-align: center !important;
        margin-left: 100px !important;
    }

    .fontResponsive {
        font-size: 21px !important;
    }

    .HandeRaiseImage {
        height: 600px !important;
        width: 100% !important;
    }

    .DivHandRaiseLapTop {
        position: absolute;
        top: 27%;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 14px !important;
    }

    .imageBodyPc {
        position: relative;
        width: 100%;
        min-height: 900px;
        height: 100%;
        background-size: 100% 100%;
        background-image: url(../Images/Sketch_of_family_2.png);
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50% !important;
        height: 100% !important;
    }

    .displaySpinCenterMarginTop {
        margin-top: 21% !important;
        padding: 0 10px;
    }

    .fontsizeResponsive {
        font-size: 25px !important;
    }

    .DivHandRaiseLapTop {
        position: absolute;
        top: 27%;
    }

    .HandRaiseImage {
        height: 500px !important;
        width: 100%;
    }

    .handRaiseImageLapTopOpacity {
        height: 650px;
        opacity: 0.4;
    }

    .heroHeader {
        width: 604px !important;
        text-align: center;
        color: rgba(77, 119, 143, 1) !important;
        font-weight: 550;
        font-family: "Times New Roman", Times, serif !important;
    }

    .fontSize24 {
        font-size: 23px !important;
    }

    .header {
        text-align: center;
        font-size: 31px;
    }

    .headerPara {
        font-size: 19px;
        height: 95px !important;
        overflow: hidden;
    }

    .missionText {
        filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
        left: 451px;
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: bold;
        font-size: 25px;
        color: rgba(77, 119, 143, 1);
    }

    .helpUsSpan {
        font-size: 55px !important;
    }

    .Uniting_Families_So_They_Can_B {
        filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
        -webkit-filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
        overflow: visible;
        text-align: center;
        font-family: Sitka Banner;
        font-style: normal;
        font-weight: normal;
    }

    .headerFont {
        font-size: 25px;
    }

    .alignTextCenter {
        font-size: 20px;
    }

    .defaultbackgroundColor {
        color: rgba(77, 119, 143, 1);
    }

    .defaultHeaderColor {
        background: none !important;
    }

    .secondHeader {
        max-width: 50%;
    }

    .ImageSection {
        height: 100%;
        width: 100%;
    }

    .postionOfParaHelpUs {
        top: 62% !important;
    }

    .centeredImagine {
        left: 50% !important;
        text-align: center;
        width: 60% !important;
    }

    .postionOfSpanHelpUs {
        top: 25%;
    }

    .fontSize20 {
        font-size: 22px !important;
    }

    .mt-2 {
        line-height: 1.3em;
        margin-top: 0 !important;
    }

    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        margin: auto !important;
        max-width: 450px;
    }

    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 650px;
        width: 450px;
    }
    .text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
        font-size: 25px !important;
    }
}

@media only screen and (min-width:1281px) and (max-width:1579px) {
    .DivHandRaiseLapTop {
        position: absolute;
        top: 27%;
    }
    .taxDedutionSponser{
        font-size: 22px !important;
    }
    .fontResponsive {
        font-size: 25px !important;
    }

    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        margin: auto !important;
        max-width: 450px;
        height: 810px !important;
    }

    .HandeRaiseImage {
        height: 500px !important;
        width: 100% !important;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 14px !important;
    }

    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 750px;
        width: 480px;
    }

    .displaySpinCenterMarginTop {
        padding: 20px;
    }

    .imageBodyPc {
        position: relative;
        width: 100%;
        min-height: 900px;
        height: 100%;
        background-size: 100% 100%;
        background-image: url(../Images/Sketch_of_family_2.png);
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50% !important;
        height: 100% !important;
    }

    .headerPara {
        font-size: 19px;
        height: 95px !important;
        overflow: hidden;
    }

    .fontsizeResponsive {
        font-size: 29px !important;
    }

    .handRaiseImageLapTopOpacity {
        height: 600px;
        opacity: 0.4;
    }

    .hero--description {
        margin-left: auto !important;
        margin-right: auto !important;
        margin-top: 20px;
        text-align: center;
        color: rgba(77, 119, 143, 1);
    }

    .ImageSection {
        height: 100%;
        width: 100%;
    }

    .heroHeader {
        width: 604px !important;
        text-align: center;
        color: rgba(77, 119, 143, 1) !important;
        font-weight: 550;
        font-family: "Times New Roman", Times, serif !important;
    }

    .postionOfParaHelpUs {
        top: 56% !important;
    }

    .centeredImagine {
        left: 49.5% !important;
        text-align: center;
    }

    .fontSize20 {
        font-size: 20px !important;
    }

    .fontSize24 {
        font-size: 24px !important;
    }

    .postionOfSpanHelpUs {
        top: 25%;
    }

    .header {
        text-align: center;
        font-size: 31px;
    }

    .marginSpace {
        margin: 150px;
    }

    .heroText {
        margin-left: 12%;
        max-width: 700px;
        position: relative;
        z-index: 2;
        color: rgba(77, 119, 143, 1);
    }

    .HandRaiseImage {
        height: 600px !important;
        width: 100%;
    }

    .marginTop9Per {
        padding-top: 15% !important;
    }
    .pSponsor{
        margin-left: 13%;
    }
}

@media only screen and (min-width:1580px) and (max-width:1650px) {
    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 750px !important;
        width: 550px !important;
    }
    .TaxDiv{
        margin-left: 0px !important;
    }
    .heroText {
        padding-left: 150px !important;
        padding-right: 20px;
        max-width: 700px;
        position: relative;
        z-index: 2;
        color: rgba(77, 119, 143, 1);
    }
    .pSponsor{
        margin-left: 12%;
    }
}

@media only screen and (min-width:1580px) {
    .DivHandRaise {
        position: absolute;
        top: 27% !important;
    }
    .TaxDiv{
        margin-left: 0px !important;
    }
    .displaySpinDonateCenter {
        display: flex;
        justify-content: center;
        margin: auto !important;
        max-width: 450px;
        height: 810px !important;
    }
    .pSponsor{
        margin-left: 12%;
    }

    .jotForm.displaySpinCenter {
        display: flex;
        justify-content: center;
        height: 750px;
        width: 550px;
    }

    .fontResponsive {
        font-size: 25px !important;
    }

    .HandeRaiseImage {
        height: 500px !important;
        width: 100% !important;
    }

    .taxDedution {
        color: #4d778f;
        font-size: 14px !important;
    }

    .displaySpinCenterMarginTop {
        padding: 20px;
    }

    .imageBodyPc {
        position: relative;
        width: 100%;
        min-height: 1000px;
        height: 100%;
        background-size: 100% 100%;
        background-image: url(../Images/Sketch_of_family_2.png);
    }

    .ImageOfFounder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50% !important;
        height: 100% !important;
    }

    .fontsizeResponsive {
        font-size: 29px !important;
    }

    .handRaiseImageLapTopOpacity {
        height: 600px;
        opacity: 0.4;
    }

    .hero--description {
        margin-left: 0 !important;
        margin-right: 0 !important;
        margin-top: 20px;
        text-align: center;
        color: rgba(77, 119, 143, 1);
    }

    .ImageSection {
        height: 100%;
        width: 100%;
    }

    .marginSpace {
        margin: 150px;
    }

    .fontSize24 {
        font-size: 25px !important;
    }

    .mt-2 {
        line-height: 1.3em;
        font-size: 30px;
        margin-top: 0 !important;
    }

    .fontSize20 {
        font-size: 25px !important;
    }

    .fontSize35 {
        font-size: 45px !important;
    }

    .postionOfParaHelpUs {
        top: 56% !important;
    }

    .centeredImagine {
        left: 51% !important;
        text-align: center;
    }

    .fontSize25 {
        font-size: 30px !important;
    }

    .postionOfSpanHelpUs {
        top: 25%;
    }

    .header {
        text-align: center;
        font-size: 31px;
    }

    .headerPara {
        font-size: 19px;
        height: 80px !important;
        overflow: hidden;
    }

    .heroHeader {
        width: 604px !important;
        text-align: center;
        color: rgba(77, 119, 143, 1) !important;
        font-weight: 550;
        font-family: "Times New Roman", Times, serif !important;
    }

    .heroText {
        padding-left: 100px;
        padding-right: 20px;
        max-width: 700px;
        position: relative;
        z-index: 2;
        color: rgba(77, 119, 143, 1);
    }

    .HandRaiseImage {
        height: 500px !important;
        width: 100%;
    }

    .marginTop9Per {
        padding-top: 14% !important;
    }
}

.partyShareVoice {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0.5%;
    width: 50%;
    color: rgba(77, 119, 143, 1);
}
.displaySpinDonateCenter {
    display: flex;
    justify-content: center;
    margin: auto !important;
    max-width: 450px;
    height: 810px;
}
.otro-blockquoteMobile {
    margin: 0px !important;
    padding: 10px;
}

.padding20 {
    padding: 20px !important;
}

.padding20Left {
    padding-left: 20px !important;
}

.padding20Right {
    padding-right: 20px !important;
}
.slideshowSliderTest{
    width:90%;
    height:500px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    margin: 20px auto;
}
.testing{
    width:100%;
    height:100%;
    object-fit: contain;
}
.padding20Top {
    padding-top: 20px !important;
}
.margin20Top{
    margin-top: 20px !important;
}