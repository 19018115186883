.whatWeDoHeader {
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    text-decoration: underline;
    color: rgba(77, 119, 143, 1);
    margin: 50px;
}

.emotional_needs_instead_of_con {
    text-align: left;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(77, 119, 143, 1);
    letter-spacing: 0.45px;
}

.aboutUsSpan2 {
    font-family: Sitka Display;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
}

#over img {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 100% !important;
    width: 100%;
    max-height: 100%;
    max-width: 100%;
}

.emotional_needs_instead_of_con {
    margin-left: 15px;
}

.marginImageAboutUsSpan {
    margin-left: 15px;
}

.WhatWeDoImg {
    opacity: 0.4;
}

.whatwedoDiv {
    margin: 20px;
}

.ShelteringSpan {
    font-size: 3rem;
    color: rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity: 1 !important;
}
.imgHowWeWork{
    height: 100%;
    width: 100%;
}
.ImageOfWhatWeDo{
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 100% !important;
    width: 80% !important;
}
.whatwedoHeader {
    margin-top: 40px;
}
.textColorBlack{
    color:black !important;
    text-align: center;
}
@media only screen and (min-width: 1025px)
{
    .h10 {
        font-size: 65px;
    }
    span.MuiStepLabel-label.MuiStepLabel-alternativeLabel.css-1hv8oq8-MuiStepLabel-label{
        font-size: 18px !important;
    }
}
.our-work-section-page .container p{
    line-height: 1.4em;
}
.hero--description {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    text-align: center;
    color: rgba(77, 119, 143, 1);
}
p.hero--description.balance-text{
    max-width: 741.777px;
}
.fontSize19{
    font-size: 19px !important;
}
@media only screen and (min-width: 1281px){
    p.hero--description.balance-text{
        font-size: 20px !important;
        max-width: 941.777px;
    }
    span.MuiStepLabel-label.MuiStepLabel-alternativeLabel.css-1hv8oq8-MuiStepLabel-label{
        font-size: 23px !important;
    }
}

.textCenter{
    text-align: center !important;
}
.css-ag7rrr-MuiTypography-root{
    text-align: center;
}
.marginAutoLeftRight{
    margin-left: auto !important;
    margin-right: auto !important;
}