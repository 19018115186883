.aboutUsFirstStatement {
    font-family: Verdana, sans-serif;
    font-style: normal;
    font-size: 19px;
    color: rgba(77, 119, 143, 1);
}

.aboutUsSpan2 {
    font-family: Sitka Display;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
}

.container-founder p {
    margin-top: 20px;
    background-color: #fff;
}

.aboutUsStatement {
    color: rgba(77, 119, 143, 1);
    font-size: 19px;
}

.founderHeaderH4 {
    font-size: 35px;
}

.textUnderLine {
    text-decoration: none !important;
}

.space {
    margin-bottom: 50px;
}

.boardTusteeHeaderDiv {
    text-align: center;
    margin-top: 50px;
    overflow: visible;
    line-height: 109px;
    font-weight: bold;
    font-size: 70px;
}

.boardTusteeHeader {
    margin-bottom: 50px;
}

.boardOfDirectorImage {
    max-width: 482px;
    width: 100%;
    height: 100%;
    max-height: 409px;
}

.BoardOfDirectorName {
    margin-top: 20px;
    color: rgba(77, 119, 143, 1);
}

.BoardOfDirectorDescription {
    margin-top: 20px;
}

.boardOfDirectorDiv {
    margin-bottom: 30px;
    margin-left: 30px;
    height: 400px;
    width: 400px;
}

.OurStaffName {
    font-size: 35px;
    text-align: start;
}

.OurStaffTitle {
    font-size: 25px;
    text-align: start;
}

.ourStaffDiv {
    margin-bottom: 30px;
    margin-left: 30px;
    height: 550px;
    width: 500px;
}

.boardTusteeName {
    font-size: 30px;
}

.fontSize20 {
    font-size: 20px !important;
}

.fontSize25 {
    font-size: 25px !important;
}

.fontSize30 {
    font-size: 30px !important;
}

@media only screen and (max-width: 480px) {
    .ourStaffDiv {
        margin: none !important;
        height: auto;
        width: auto;
    }

    .space {
        margin-bottom: 45px
    }

    .textAlignCenterMobile {
        text-align: left;
    }

    .boardTusteeHeaderDiv {
        text-align: center;
        margin-top: 50px;
        overflow: visible;
        line-height: 109px;
        font-weight: bold;
        font-size: 70px;
    }

    .boardTusteeHeader {
        margin-bottom: 50px;
    }

    .boardOfDirectorsImage {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100%;
        width: 100%;
    }

    .boardTusteeRole {
        text-align: center;
    }

    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 350px !important;
        max-width: 350px !important;
        float: left;
        padding-right: 10px;
    }

    div#modal-modal-description {
        padding: 10px;
    }
    .imageDiv {
        max-height: 330px !important;
        max-width: 309px !important;
        height: 100%;
        width: 100%;
        margin: auto !important;
    }
    .imageDivText{
        margin: auto;
        text-align: center;
    }
}

@media only screen and (min-width: 481px) and (max-width: 660px) {
    .missionText {
        font-size: 15px !important;
    }

    .fontsizeResponsive {
        font-size: 20px !important;
    }

    .boardOfDirectorsImage {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100%;
        width: 100%;
    }

    .boardTusteeRole {
        text-align: center;
    }

    .ImageOfWhatWeDo {
        max-width: 600px !important;
        max-height: 600px !important;
    }

    .spaceBetweenFooter {
        margin-bottom: 13.5% !important;
    }

    .fontChange {
        font-size: 17px;
    }
    .imageDiv {
        max-height: 330px !important;
        max-width: 309px !important;
        height: 100%;
        width: 100%;
        margin: auto !important;
    }
    .imageDivText{
        margin: auto;
        text-align: center;
    }
}

@media only screen and (min-width: 661px) and (max-width: 780px) {
    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 350px !important;
        max-width: 350px !important;
        float: left;
        padding-right: 10px;
    }
    .imageDiv {
        max-height: 330px !important;
        max-width: 309px !important;
        height: 100%;
        width: 100%;
        margin: auto !important;
    }
    .imageDivText{
        margin: auto;
        text-align: center;
    }

    .boardOfDirectorsImage {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100%;
        width: 100%;
    }

    .missionText {
        font-size: 15px !important;
    }

    .fontsizeResponsive {
        font-size: 20px !important;
    }

    h1.hero--header.balance-text.mx-auto.font-heading.h20.lg-text-3xl {
        width: auto !important
    }

    .boardTusteeRole {
        text-align: center;
    }

    .spaceBetweenFooter {
        margin-bottom: 13.5% !important;
    }

    .fontChange {
        font-size: 17px;
    }
}

@media only screen and (min-width: 781px) and (max-width: 920px) {
    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 600px !important;
        max-width: 550px !important;
        float: left;
        padding-right: 10px;
    }

    .boardOfDirectorsImage {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100%;
        width: 100%;
    }

    .missionText {
        font-size: 18px !important;
    }

    h1.hero--header.balance-text.mx-auto.font-heading.h20.lg-text-3xl {
        width: auto !important
    }

    .boardTusteeRole {
        text-align: left;
    }

    .fontChange {
        font-size: 17px;
    }
}

@media only screen and (min-width: 921px) and (max-width: 1024px) {
    .missionText {
        font-size: 20px !important;
    }

    .ourStaffDiv {
        margin: none !important;
        height: auto;
        width: auto;
    }

    .textAlignCenterMobile {
        text-align: left;
    }

    .boardTusteeHeaderDiv {
        text-align: center;
        margin-top: 50px;
        overflow: visible;
        line-height: 109px;
        font-weight: bold;
        font-size: 70px;
    }

    .boardTusteeHeader {
        margin-bottom: 50px;
    }

    .boardOfDirectorsImage {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100%;
        width: 100%;
    }

    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 600px !important;
        max-width: 550px !important;
        float: left;
        padding-right: 10px;
    }

    h1.hero--header.balance-text.mx-auto.font-heading.h20.lg-text-3xl {
        width: auto !important
    }

    .spaceBetweenFooter {
        margin-bottom: 13.5% !important;
    }
}

@media only screen and (min-width: 1025px) and (max-width:1160px) {
    .missionText {
        font-size: 20px !important;
    }

    h1.hero--header.balance-text.mx-auto.font-heading.h20.lg-text-3xl {
        width: auto !important
    }

    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 600px !important;
        max-width: 550px !important;
        float: left;
        padding-right: 10px;
    }

    .boardOfDirectorsImage {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100%;
        width: 100%;
    }

    /* footer.text-center.text-lg-start.text-white.defaultbackgroundColorToBlue {
        margin-top: 130px !important;
    } */
    .spaceBetweenFooter {
        margin-bottom: 13.5% !important;
    }
}

@media only screen and (min-width: 1161px) and (max-width:1280px) {
    .missionText {
        font-size: 26px !important;
    }

    .ourStaffDiv {
        margin: none !important;
        height: auto;
        width: auto;
    }

    .textAlignCenterMobile {
        text-align: left;
    }

    .boardTusteeHeaderDiv {
        text-align: center;
        margin-top: 50px;
        overflow: visible;
        line-height: 109px;
        font-weight: bold;
        font-size: 70px;
    }

    .boardTusteeHeader {
        margin-bottom: 50px;
    }

    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 600px !important;
        max-width: 550px !important;
        float: left;
        padding-right: 10px;
    }

    .boardOfDirectorsImage {
        max-height: 100% !important;
        max-width: 100% !important;
        height: 100%;
        width: 100%;
    }

    /* footer.text-center.text-lg-start.text-white.defaultbackgroundColorToBlue {
        margin-top: 350px !important;
    } */
    .spaceBetweenFooter {
        margin-bottom: 13.5% !important;
    }
}

.textIndent30 {
    text-indent: 30px;
}

.divImageWidth {
    height: 50%;
    width: 50%;
}

.marginNone {
    padding: 0 !important;
    margin: 0 !important;
}

.founderDiv {
    width: 100%;
    margin: 0 auto;
}

.textAlignRight {
    text-align: end !important;
}

@media only screen and (min-width:1281px) and (max-width:1579px) {
    .displayAutoFormat {
        height: 100% !important;
        width: 100% !important;
    }

    .missionText {
        font-size: 26px !important;
    }

    /* footer.text-center.text-lg-start.text-white.defaultbackgroundColorToBlue {
        margin-top: 200px !important;
    } */

    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 600px !important;
        max-width: 650px !important;
        float: left;
        padding-right: 10px;
    }

    .boardOfDirectorsImage {
        max-height: 328px !important;
        max-width: 309px !important;
        height: 100%;
        width: 100%;
    }


    .spaceBetweenFooter {
        margin-bottom: 9.5% !important;
    }
}

@media only screen and (min-width:1580px) and (max-width:1732px) {
    .missionText {
        font-size: 26px !important;
    }

    .spaceBetweenFooter {
        margin-bottom: 9.5% !important;
    }

    /* footer.text-center.text-lg-start.text-white.defaultbackgroundColorToBlue {
        margin-top: 250px !important;
    } */
    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 700px !important;
        max-width: 650px !important;
        float: left;
        padding-right: 10px;
    }

    .boardOfDirectorsImage {
        max-height: 428px !important;
        max-width: 509px !important;
        height: 100%;
        width: 100%;
    }
}

@media only screen and (min-width:1733px) {
    .missionText {
        font-size: 30px !important;
    }

    .spaceBetweenFooter {
        margin-bottom: 9.5% !important;
    }

    .founderImage {
        height: 100% !important;
        width: 100% !important;
        max-height: 900px !important;
        max-width: 800px !important;
        float: left;
        padding-right: 10px;
    }

    .boardOfDirectorsImage {
        /* max-height: 428px !important;
        max-width: 509px !important; */
        height: 100%;
        width: 100%;
    }
}

@media only screen and (min-width: 641px) {
    .side-by-side {
        flex-direction: row;
    }
}

.side-by-side {
    display: flex;
    flex-direction: column;
}

.side-by-side .side {
    vertical-align: middle;
    flex: 1 1;
}

.mr-2 {
    margin-right: 20px !important;
}

.rounded {
    border-radius: 0.25rem !important;
}

.p40 {
    font-size: 21px;
}

.py-3 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
}

.justify-center {
    justify-content: center !important;
}

.flex {
    display: flex !important;
}

.flex-col {
    flex-direction: column !important;
}

.rounded {
    transform: translateZ(0);
    max-width: 100%;
}

.samarthPhotoRow {
    margin-top: 50px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

.marginBottom40 {
    margin-bottom: 40px !important;
}

.backgroundColorDefault {
    color: rgba(77, 119, 143, 1);
}


.boardTusteeRoleColor {
    color: #b3b3a6;
    font-weight: bold;
    font-size: 23px !important;
}

.marginTop20 {
    margin-top: 20px;
}

span.p40.backgroundColorDefault {
    /* font-weight: 600; */
}

.WhatWeDo {
    max-height: 100% !important;
    max-width: 100% !important;
    height: 100%;
    width: 100%;
}

.colorBlack {
    color: black;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    padding: 26px 16px 10px 10px !important
}

.spaceLeft {
    margin-right: 15px !important;
}

.card-header.defaultHeaderColor.textAlignCenter {
    margin-bottom: 15px;
}

@media only screen and (min-width: 1281px) {
    .p40 {
        font-size: 24px;
    }


    .samarthPhotoRow {
        margin-top: 50px;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }
}

.margin50 {
    margin: 50px !important
}




#wrapper {
    display: flex;
    width: 100%;
    justify-content: space-around;
}

.party {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 0.5%;
    width: 33.33%;
    color: rgba(77, 119, 143, 1);
}

.party-image-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding: 0.3%;
    padding-top: 100%;
    margin: 0.5%;
    max-width: 100%;
    max-height: 100%;
}

.partyImages {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

.img-fluid {
    min-height: 200px;
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.contactUsImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    object-fit: cover;
}

.fontSize34 {
    font-size: 34px !important;
}

.marginTop7Percent {
    margin-top: 7%;
}

.fontSize20 {
    font-size: 20px !important
}
.paddingLeft20{
    padding-left: 20px !important;
}