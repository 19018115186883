section.section {
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
}

.ImageSection {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.sketchImage {
    max-width: 100%;
    max-height: 100%;
    display: block;
    opacity: 0.3;
}

.Uniting_Families_So_They_Can_B {
    filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
    -webkit-filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: normal;
}

.alignHeaderDefault {
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: normal;
}

.alignHeaderDefaultWithDifferentFont {
    overflow: visible;
    text-align: center;
    font-style: normal;
    font-weight: normal;
}

.fontWeightBold {
    font-weight: 600 !important;
}

.headerFont35 {
    font-size: 35px;
}

.headerdefaultformator {
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
}

.defaultOtherFormator {
    overflow: visible;
    text-align: center;
    font-style: normal;
    font-weight: bold;
}

.Uniting_Families_So_They_Can_B_WhiteColor {
    color: rgba(252, 253, 253, 1);
}

.Uniting_Families_So_They_Can_B_defaultColor {
    color: rgba(77, 119, 143, 1);
}

.Uniting_Families_So_They_Can_B_Black {
    color: black
}

.Uniting_Families {
    font-size: 45px;
    position: absolute;
    top: 3%;
    width: 100%;
    text-align: center;
}

.backgroundsecondHeader {
    position: absolute;
    top: 5%;
    width: 100%;
    text-align: center;
}

.textCapitalize {
    text-transform: capitalize;
}

.textUpper {
    text-transform: uppercase;
}

.displayWaterMarkPhoto {
    height: 100%;
    width: 100%;
    opacity: 0.3;
    transition: transform .5s ease;
    border: 10px solid rgba(77, 119, 143, 1);
}

.displayWaterMarkPhoto:hover {
    transform: scale(1.2);
}

.colsmDisplay {
    margin: 0;
    left: 0;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.displayWaterMarkImage {
    justify-content: center;
    width: 100%;
    display: flex;
}

.flexCenter {
    justify-content: center;
    display: flex;
}

.centered {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    font-family: Sitka Banner;
    font-style: normal;
}

/* .postionOfParaHelpUs {
    top: 50%;
    color: chocolate;
    font-size: 15px !important;
} */


.postionOfSpanAccommodation {
    top: 20%;
}

.postionOfParaAccommodation {
    top: 58%;
}

.postionOfSpanDonation {
    top: 18%;
}

.postionOfParaDonation {
    top: 57%;
}

.imagesToBeCenter {
    margin: auto;
    display: flex;
    justify-content: center;
}

.headerFont {
    left: 0px;
    white-space: nowrap;
    font-size: 50px;
    text-decoration: underline;
    font-weight: bold;
    filter: none !important
}

.floralwhite {
    color: floralwhite;
}

.defaultColor {
    color: rgba(77, 119, 143, 1) !important;
}
.css-1vyamtt-MuiStepLabel-labelContainer{
    color: rgba(77, 119, 143, 1) !important;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-active{
    color: rgba(77, 119, 143, 1) !important;
}

.defaultWhiteColor {
    color: #fff;
}

.defaultbackgroundColorToBlue {
    background-color: rgba(77, 119, 143, 1);
}

.defaultHeaderColor {
    background: none !important;
}

.alignImageDiv {
    height: 550px;
    width: 550px;
}

.alignTextCenter {
    color: rgba(77, 119, 143, 1);
    font-size: 28px;
    font-weight: bold;
    font-family: Sitka Banner;
    font-style: normal;
}

.aboutUs {
    margin-top: 50px;
}

.founderStorySpan {
    color: rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity: 1 !important;
    font-size: 2.5rem !important;
}

.mt-2 {
    line-height: 1.3em;
    margin-top: 0 !important;
}

.boardImageDiv {
    justify-content: center;
    display: flex;
}

.cardBoardImage {
    width: 100%;
    height: 450px;
}

.spanLink {
    text-align: center;
    margin-top: 10px;
}

img.boardImage {
    width: 100%;
    border-radius: 25%;
}

img.hotelImages {
    width: 60%;
    margin: 0 auto;
    display: block;
    border-radius: 20px !important;
}

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 700px;
}
.slideshowMobile{
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
}

.slideshowHotels{
    margin: 0 auto;
    overflow: hidden;
    max-width: 800px;
    width: 100%;
}
.HospitalImageDesktop{
    padding-bottom: 50px;
    display: block;
    width: 100%;
    margin: 0 auto;
}
.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    height: auto;
    width: 100%;
    border-radius: 40px;
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;

    cursor: pointer;
    margin: 15px 7px 0px;

    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: #6a0dad;
}

.slideshowNames {
    text-align: center;
}

.slideshowName {
    background-color: #c4c4c4;
}

.slideshowName.active {
    background-color: #c4c4c4;
}

.imageBoxSlider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: 'translate(-50%, -50%)';
    width: 400px;
    background-color: 'background.paper';
    border: '2px solid #000';
    box-shadow: 24px;
    padding: 4px;
}

.main-body {
    background-color: #fff;
}

.card,
.card-header,
.card-body {
    border: none !important;
}

.alignTextLeft {
    text-align: left !important;
}

.missionTextFontSize {
    font-size: 20px;
}

.textAlignCenter {
    text-align: center;
}

.borderNone {
    border: none !important;
    box-shadow: none !important;
}

.fontSize35 {
    font-size: 35px !important;
}

.fontSize40 {
    font-size: 40px !important;
}

.fontSize50 {
    font-size: 50px !important;
}

.fontSize55 {
    font-size: 55px !important;
}

.fontSize60 {
    font-size: 60px !important;
}

.backgroundColorWhite {
    background-color: #fff;
}

.colorWhite {
    color: #fff;
}

.GeneralFont {
    filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
    overflow: visible;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    color: rgba(77, 119, 143, 1);
}

.margin10top {
    margin-top: 10px;
}

.margin10bottom {
    margin-bottom: 10px !important;
}

.margin20top {
    margin-top: 20px !important;
}

.margin20bottom {
    margin-bottom: 20px !important;
}

.circularSpin {
    width: 200px !important;
    height: 200px !important;
}

.spinLeft {
    position: absolute;
    top: 25%;
    transform: translateX(-50%) translateY(-50%);
}

iframe {
    background-color: transparent;
}

.hotelImageBody {
    text-align: center;
    border: none !important;
}

.hotelDialogName {
    text-align: center;
}

.btnAchorTag {
    background-color: white;
    border: 2px solid black;
    color: green;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    margin: 10px 30px;
    cursor: pointer;
}

button.btnEmailIcon {
    background-color: transparent;
    border: none;
}

.secondHeader {
    max-width: 60%;
}

.cardMarginBottom10 {
    margin-bottom: 30px;
}
.marginBottom15 {
    margin-bottom: 15px !important;
}

.marginTop15 {
    margin-top: 15px !important;
}

.spanApplyForAccommodation {
    font-size: 20px;
    font-weight: bold !important;
    color: rgba(77, 119, 143, 1);
}

.left-img {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
    margin-right: 12px;
    vertical-align: middle;
}

.container {
    max-width: 100%;
}

.text {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alignImageWhatWeDo {
    height: 100%;
    width: 100%;
    margin-right: 12px;
    vertical-align: middle;
}

img.HotelImage {
    height: 100px;
    width: 200px;
}

.fontSize15 {
    font-size: 15px !important;
}

.hotelNameDiv {
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.textAlignLeft {
    text-align: left !important;
}

.fontSize45 {
    font-size: 45px !important;
}

.textDecorationUnderline {
    text-decoration: underline;
}

span.firstHeader {
    color: aqua;
}

.cardPartnerHotels {
    border: 1px solid lightblue !important;
}

.missionText {
    filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
    left: 451px;
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(77, 119, 143, 1);
}

a.onlineApplication {
    color: #0d6efd !important;
}

.margin30bottom {
    margin-bottom: 30px !important;
}

.margin35bottom {
    margin-bottom: 35px;
}

.margin40bottom {
    margin-bottom: 40px;
}

.margin45bottom {
    margin-bottom: 45px;
}
.margin50bottom {
    margin-bottom: 50px !important;
}

.margin30top {
    margin-top: 30px !important;
}

.margin35top {
    margin-top: 35px;
}

.margin40top {
    margin-top: 40px;
}

.margin45top {
    margin-top: 45px;
}

.margin50top {
    margin-top: 50px;
}

.width100percent {
    width: 100%;
}

.width80percent {
    width: 90%;
}

.width80percent {
    width: 80%;
}

.width70percent {
    width: 70%;
}

.width60percent {
    width: 60%;
}

.width50percent {
    width: 50%;
}

.fontStyleItalic {
    font-style: italic;
    text-align: left;
}

.fontSize10 {
    font-size: 10px !important;
}

.fontSize14 {
    font-size: 14px !important;
}

.fontSize13 {
    font-size: 13px !important;
}

.fontSize12 {
    font-size: 12px !important;
}

.cardMargin10left {
    margin-left: 10px;
}

.cardMargin18left {
    margin-left: 18px;
}

.cardMargin21left {
    margin-left: 21px;
}

.cardMargin15left {
    margin-left: 15px;
}

.mb-4 {
    margin-bottom: 0 !important
}

.card-body {
    padding: none !important;
}

.fontSize38 {
    font-size: 38px !important;
}

.textDecorationUnderlineBackgroundBlue {
    text-decoration: underline blue;
}
.fontSize23{
    font-size: 23px !important;
}
.paddingNone{
    padding: 0 !important;
}
.card, .card-header, .card-body{
    border: none !important;
    padding-top: 0px;
}
.padding10{
    padding: 10px !important;
}
.fadedColor{
    color: rgb(5 65 99);
}
.marginLeft10{
    margin-left: 10px;
}
.paddingLeft10{
    padding-left: 10px;
}
.marginBottom10{
    margin-bottom: 10px;
}
.marginLeft20{
    margin-left: 20px;
}
.marginLeft30{
    margin-left: 30px;
}
.marginLeft50{
    margin-left: 50px;
}
.marginLeft60{
    margin-left: 60px;
}
.marginLeft70{section.section {
    width: 100%;
    height: 100%;
    margin: 0;
    position: relative;
}

.ImageSection {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.sketchImage {
    max-width: 100%;
    max-height: 100%;
    display: block;
    opacity: 0.3;
}

.Uniting_Families_So_They_Can_B {
    filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
    -webkit-filter: drop-shadow(0px 3px 6px rgba(4, 0, 0, 1));
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: normal;
}

.alignHeaderDefault {
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: normal;
}

.alignHeaderDefaultWithDifferentFont {
    overflow: visible;
    text-align: center;
    font-style: normal;
    font-weight: normal;
}

.fontWeightBold {
    font-weight: 600 !important;
}

.headerFont35 {
    font-size: 35px;
}

.headerdefaultformator {
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
}

.defaultOtherFormator {
    overflow: visible;
    text-align: center;
    font-style: normal;
    font-weight: bold;
}

.Uniting_Families_So_They_Can_B_WhiteColor {
    color: rgba(252, 253, 253, 1);
}

.Uniting_Families_So_They_Can_B_defaultColor {
    color: rgba(77, 119, 143, 1);
}

.Uniting_Families_So_They_Can_B_Black {
    color: black
}

.Uniting_Families {
    font-size: 45px;
    position: absolute;
    top: 3%;
    width: 100%;
    text-align: center;
}

.backgroundsecondHeader {
    position: absolute;
    top: 5%;
    width: 100%;
    text-align: center;
}

.textCapitalize {
    text-transform: capitalize;
}

.textUpper {
    text-transform: uppercase;
}

.displayWaterMarkPhoto {
    height: 100%;
    width: 100%;
    opacity: 0.3;
    transition: transform .5s ease;
    border: 10px solid rgba(77, 119, 143, 1);
}

.displayWaterMarkPhoto:hover {
    transform: scale(1.2);
}

.colsmDisplay {
    margin: 0;
    left: 0;
    text-align: center;
    overflow: hidden;
    position: relative;
}

.displayWaterMarkImage {
    justify-content: center;
    width: 100%;
    display: flex;
}

.flexCenter {
    justify-content: center;
    display: flex;
}

.centered {
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: bold;
    font-family: Sitka Banner;
    font-style: normal;
}

/* .postionOfParaHelpUs {
    top: 50%;
    color: chocolate;
    font-size: 15px !important;
} */


.postionOfSpanAccommodation {
    top: 20%;
}

.postionOfParaAccommodation {
    top: 58%;
}

.postionOfSpanDonation {
    top: 18%;
}

.postionOfParaDonation {
    top: 57%;
}

.imagesToBeCenter {
    margin: auto;
    display: flex;
    justify-content: center;
}

.headerFont {
    left: 0px;
    white-space: nowrap;
    font-size: 50px;
    text-decoration: underline;
    font-weight: bold;
    filter: none !important
}

.floralwhite {
    color: floralwhite;
}

.defaultColor {
    color: rgba(77, 119, 143, 1) !important;
}
.css-1vyamtt-MuiStepLabel-labelContainer{
    color: rgba(77, 119, 143, 1) !important;
}
.css-1hv8oq8-MuiStepLabel-label.Mui-active{
    color: rgba(77, 119, 143, 1) !important;
}

.defaultWhiteColor {
    color: #fff;
}

.defaultbackgroundColorToBlue {
    background-color: rgba(77, 119, 143, 1);
}

.defaultHeaderColor {
    background: none !important;
}

.alignImageDiv {
    height: 550px;
    width: 550px;
}

.alignTextCenter {
    color: rgba(77, 119, 143, 1);
    font-size: 28px;
    font-weight: bold;
    font-family: Sitka Banner;
    font-style: normal;
}

.aboutUs {
    margin-top: 50px;
}

.founderStorySpan {
    color: rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity: 1 !important;
    font-size: 2.5rem !important;
}

.mt-2 {
    line-height: 1.3em;
    margin-top: 0 !important;
}

.boardImageDiv {
    justify-content: center;
    display: flex;
}

.cardBoardImage {
    width: 100%;
    height: 450px;
}

.spanLink {
    text-align: center;
    margin-top: 10px;
}

img.boardImage {
    width: 100%;
    border-radius: 25%;
}

img.hotelImages {
    width: 60%;
    margin: 0 auto;
    display: block;
    border-radius: 20px !important;
}
img.hospitalImages{
    width: 50%;
    margin: 0 auto;
    display: block;
}

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 500px;
}

.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
}

.slide {
    display: inline-block;
    height: auto;
    width: 100%;
    border-radius: 40px;
}

/* Buttons */

.slideshowDots {
    text-align: center;
}

.slideshowDot {
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;

    cursor: pointer;
    margin: 15px 7px 0px;

    background-color: #c4c4c4;
}

.slideshowDot.active {
    background-color: #6a0dad;
}

.slideshowNames {
    text-align: center;
}

.slideshowName {
    background-color: #c4c4c4;
}

.slideshowName.active {
    background-color: #c4c4c4;
}

.imageBoxSlider {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: 'translate(-50%, -50%)';
    width: 400px;
    background-color: 'background.paper';
    border: '2px solid #000';
    box-shadow: 24px;
    padding: 4px;
}

.main-body {
    background-color: #fff;
}

.card,
.card-header,
.card-body {
    border: none !important;
}

.alignTextLeft {
    text-align: left !important;
}

.missionTextFontSize {
    font-size: 20px;
}

.textAlignCenter {
    text-align: center;
}

.borderNone {
    border: none !important;
    box-shadow: none !important;
}

.fontSize35 {
    font-size: 35px !important;
}

.fontSize40 {
    font-size: 40px !important;
}

.fontSize50 {
    font-size: 50px !important;
}

.fontSize55 {
    font-size: 55px !important;
}

.fontSize60 {
    font-size: 60px !important;
}

.backgroundColorWhite {
    background-color: #fff;
}

.colorWhite {
    color: #fff;
}

.GeneralFont {
    filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
    overflow: visible;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    color: rgba(77, 119, 143, 1);
}

.margin10top {
    margin-top: 10px;
}

.margin10bottom {
    margin-bottom: 10px !important;
}

.margin20top {
    margin-top: 20px !important;
}

.margin20bottom {
    margin-bottom: 20px !important;
}

.circularSpin {
    width: 200px !important;
    height: 200px !important;
}

.spinLeft {
    position: absolute;
    top: 25%;
    transform: translateX(-50%) translateY(-50%);
}

iframe {
    background-color: transparent;
}

.hotelImageBody {
    text-align: center;
    border: none !important;
}

.hotelDialogName {
    text-align: center;
}

.btnAchorTag {
    background-color: white;
    border: 2px solid black;
    color: green;
    padding: 5px 10px;
    text-align: center;
    display: inline-block;
    font-size: 20px;
    margin: 10px 30px;
    cursor: pointer;
}

button.btnEmailIcon {
    background-color: transparent;
    border: none;
}

.secondHeader {
    max-width: 60%;
}

.cardMarginBottom10 {
    margin-bottom: 30px;
}
.marginBottom15 {
    margin-bottom: 15px !important;
}

.marginTop15 {
    margin-top: 15px !important;
}

.spanApplyForAccommodation {
    font-size: 20px;
    font-weight: bold !important;
    color: rgba(77, 119, 143, 1);
}

.left-img {
    max-height: 100%;
    max-width: 100%;
    height: 100%;
    width: 100%;
    margin-right: 12px;
    vertical-align: middle;
}

.container {
    max-width: 100%;
}

.text {
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
}

.alignImageWhatWeDo {
    height: 100%;
    width: 100%;
    margin-right: 12px;
    vertical-align: middle;
}

img.HotelImage {
    height: 100px;
    width: 200px;
}

.fontSize15 {
    font-size: 15px !important;
}

.hotelNameDiv {
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.textAlignLeft {
    text-align: left !important;
}

.fontSize45 {
    font-size: 45px !important;
}

.textDecorationUnderline {
    text-decoration: underline;
}

span.firstHeader {
    color: aqua;
}

.cardPartnerHotels {
    border: 1px solid lightblue !important;
}

.missionText {
    filter: drop-shadow(0px 3px 6px rgba(254, 254, 248, 1));
    left: 451px;
    overflow: visible;
    text-align: center;
    font-family: Sitka Banner;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    color: rgba(77, 119, 143, 1);
}

a.onlineApplication {
    color: #0d6efd !important;
}

.margin30bottom {
    margin-bottom: 30px !important;
}

.margin35bottom {
    margin-bottom: 35px;
}

.margin40bottom {
    margin-bottom: 40px;
}

.margin45bottom {
    margin-bottom: 45px;
}
.margin50bottom {
    margin-bottom: 50px !important;
}

.margin30top {
    margin-top: 30px !important;
}

.margin35top {
    margin-top: 35px;
}

.margin40top {
    margin-top: 40px;
}

.margin45top {
    margin-top: 45px;
}

.margin50top {
    margin-top: 50px;
}

.width100percent {
    width: 100%;
}

.width80percent {
    width: 90%;
}

.width80percent {
    width: 80%;
}

.width70percent {
    width: 70%;
}

.width60percent {
    width: 60%;
}

.width50percent {
    width: 50%;
}

.fontStyleItalic {
    font-style: italic;
    text-align: left;
}

.fontSize10 {
    font-size: 10px !important;
}

.fontSize14 {
    font-size: 14px !important;
}

.fontSize13 {
    font-size: 13px !important;
}

.fontSize12 {
    font-size: 12px !important;
}

.cardMargin10left {
    margin-left: 10px;
}

.cardMargin18left {
    margin-left: 18px;
}

.cardMargin21left {
    margin-left: 21px;
}

.cardMargin15left {
    margin-left: 15px;
}

.mb-4 {
    margin-bottom: 0 !important
}

.card-body {
    padding: none !important;
}

.fontSize38 {
    font-size: 38px;
}

.textDecorationUnderlineBackgroundBlue {
    text-decoration: underline blue;
}
.fontSize23{
    font-size: 23px !important;
}
.paddingNone{
    padding: 0 !important;
}
.card, .card-header, .card-body{
    border: none !important;
    padding-top: 0px;
}
.padding10{
    padding: 10px !important;
}
.fadedColor{
    color: rgb(5 65 99);
}
.marginLeft10{
    margin-left: 10px;
}
.paddingLeft10{
    padding-left: 10px;
}
.marginBottom10{
    margin-bottom: 10px;
}
.marginLeft20{
    margin-left: 20px;
}
.marginLeft30{
    margin-left: 30px;
}
.marginLeft50{
    margin-left: 50px;
}
.marginLeft60{
    margin-left: 60px;
}
.marginLeft70{
    margin-left: 70px;
}
.marginLeft80{
    margin-left: 80px;
}
.marginLeft90{
    margin-left: 90px;
}
.marginLeft100{
    margin-left: 100px;
}
.marginLeft110{
    margin-left: 110px;
}
.marginLeft120{
    margin-left: 120px;
}
.paddingRight10{
    padding-right: 10px;
}

.imageBody {
    position: relative;
    width: 100%;
    min-height: 800px;
    height: 100%;
    background-size: 100% 100%;
    
}

.hero--text{
    padding-left: 20px;
    padding-right: 20px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    color: rgba(77, 119, 143, 1);
}

.marginTop180{
    margin-top: 180px !important;
}
.marginTop99{
    margin-top: 99px !important;
}

.marginTop60{
    margin-top: 60px !important;
}
.font-heading{
    font-family: Kazimir,ui-serif,Georgia,Cambria,Times New Roman,Times,serif!important;
}
.cw-infosit-homepage .hero--text .hero--header{
    font-size: 33px;
}
.max-w-md{
    max-width: 800px!important;
}
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
.text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
    font-size: 28px !important;
    color: #77a8bb;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .0625rem;
    text-align: center;
}
.mb-2 {
    margin-bottom: 20px!important;
}
.block {
    display: block!important;
}
.serif-heading, .text-kazimir {
    font-family: Kazimir,Georgia,Cambria,Times New Roman,Times,serif;
}
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
.leading-normal {
    line-height: 1.5!important;
}
.h40 {
    font-size: 26px;
}
.mt-2{
    margin-top: 20px!important;
}
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
p {
    line-height: 1.3em;
    font-size: 16px;
}
.max-w-md.mx-auto{
    margin-top: 80px;
}
p.balance-text.mx-auto.mt-2{
    text-align: center;
}
.marginTop50{
    margin-top: 50px;
}
.marginTop100{
    margin-top: 100px;
}
.p30{
    font-size:19px;
}
.max-w-sm{
    max-width: 700px!important;
    text-align: center;
    
}

.FontSizeTimesNewRoman{
    font-family: "Times New Roman", Times, serif !important;
}

.marginTop25{
    margin-top: 25px;
}
h1.hero--header.balance-text.mx-auto.font-heading.h20.lg-text-3xl{
    text-align: center;
    color: rgba(77, 119, 143, 1);
    font-weight: 550;
    width: 604px;
    font-family: "Times New Roman", Times, serif !important;
}
p.hero--description.balance-text.p30{
    font-size: 17px !important;
    text-align: justify;
    color: rgba(77, 119, 143, 1);
    font-family: "Times New Roman", Times, serif;
    font-weight: 550;
    max-width: 540px;
}
.marginTopModifier{
    margin-top: 40px !important;
}
.HospitalName{
    text-align: center;
}
.justifyText{
    text-align: justify;
}
.fontFamilyTimesNewRoman{
    font-family: "Times New Roman", Times, serif;
}
a:hover{
    color: #004d99;
}
.uppercase {
    text-transform: uppercase!important;
}
.mt-2 {
    margin-top: 20px!important;
}
.mb-1 {
    margin-bottom: 10px!important;
}
.leading-normal {
    line-height: 1.5!important;
}
.text-xs {
    font-size: .75rem!important;
}
.block {
    display: block!important;
}
a {
    text-decoration: underline;
    /* color: #036; */
    font-weight: 500;
    transition: color .15s linear;
    -webkit-tap-highlight-color: rgba(0,0,0,.1);
    outline: none;
}
.marginLeft20{
    margin-left: 20;
}
.marginLeft30{
    margin-left: 30;
}
.imageOfText{
    padding-right:10px;
}

.cardContent{
    max-height: 100px;
}
.colorWhite{
    background-color: white;
}
.fontSize26{
    font-size: 26px !important;
}
.css-1ndgitk{
    padding-left: 16px !important;
    padding-right: 2px;
}
.fontSize16{
    font-size: 16px !important;
}
/* .MuiCardContent-root.css-46bh2p-MuiCardContent-root{
    height: 230px;
} */
.marginBottom20{
    margin-bottom: 20px;
}
.marginLeft0{
    margin-left: 0 !important;
}
.marginTop15{
    margin-top: 15px !important;
}
@media only screen and (min-width: 1281px){
   
    .mt-2 {
        line-height: 1.3em;
        margin-top: 0 !important;
    }
    .leading-normal{
        font-size: 18px !important;
    }
    .fontSize16{
        font-size: 20px !important;
    }
    .fontSize26{
        font-size: 37px !important;
    }
    .fontSize30{
        font-size: 30px !important;
    }
    /* .MuiCardContent-root.css-46bh2p-MuiCardContent-root{
        height: 230px !important;
    } */
    .imageBody{
        position: relative;
        width: 100%;
        min-height: 900px;
        height: 100%;
        background-size: 100% 100%;
    }
}
.fontSize17{
    font-size: 17px !important;
}
.textDecorativeNone{
    text-decoration: none !important;
}
.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
.borderOfCard{
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    overflow: hidden;
    display: flex;
}
.headerTitle{
    text-align: justify;
}
/* .jotForm.displaySpinCenter{
    display: flex;
    justify-content: center;
     height: 650px;
    margin:auto !important;
    margin-top: 100px !important;
    max-width: 450px;
} */
.header{
    text-align: center;
}
.fontSize18{
    font-size:18px !important
}
.colorBrown{
    color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity: 1 !important;
}
.fontsize35{
    font-size:35px !important
}
.marginTop30Per{
    margin-top:  30% !important;
}
.paddingBottom10{
    padding-bottom: 10px !important;
}
    margin-left: 70px;
}
.marginLeft80{
    margin-left: 80px;
}
.marginLeft90{
    margin-left: 90px;
}
.marginLeft100{
    margin-left: 100px;
}
.marginLeft110{
    margin-left: 110px;
}
.marginLeft120{
    margin-left: 120px;
}
.paddingRight10{
    padding-right: 10px;
}

.imageBody {
    position: relative;
    width: 100%;
    min-height: 800px;
    height: 100%;
    background-size: 100% 100%;
}

.hero--text{
    padding-left: 20px;
    padding-right: 20px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 2;
    color: rgba(77, 119, 143, 1);
}
.heroText{
    padding-left: 20px;
    padding-right: 20px;
    max-width: 700px;
    position: relative;
    z-index: 2;
    color: rgba(77, 119, 143, 1);
}
.heroTextMobile{
    margin: auto !important;
    max-width: 700px;
    position: relative;
    z-index: 2;
    color: rgba(77, 119, 143, 1);
    padding-top: 30px;
}


.marginTop180{
    margin-top: 180px !important;
}
.marginTop99{
    margin-top: 99px !important;
}

.marginTop60{
    margin-top: 60px !important;
}
.font-heading{
    font-family: Kazimir,ui-serif,Georgia,Cambria,Times New Roman,Times,serif!important;
}
.cw-infosit-homepage .hero--text .hero--header{
    font-size: 33px;
}
.max-w-md{
    max-width: 800px!important;
}
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
.text-eyebrow-cw-secondary, .text-eyebrow-cw-med-blue{
    font-size: 28px !important;
    color: #77a8bb;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: .0625rem;
    text-align: center;
}
.mb-2 {
    margin-bottom: 20px!important;
}
.block {
    display: block!important;
}
.serif-heading, .text-kazimir {
    font-family: Kazimir,Georgia,Cambria,Times New Roman,Times,serif;
}
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
.leading-normal {
    line-height: 1.5!important;
}
.h40 {
    font-size: 26px;
}
.mt-2{
    margin-top: 20px!important;
}
.mx-auto {
    margin-left: auto!important;
    margin-right: auto!important;
}
p {
    line-height: 1.3em;
    font-size: 16px;
}
.max-w-md.mx-auto{
    margin-top: 80px;
}
p.balance-text.mx-auto.mt-2{
    text-align: center;
}
.marginTop50{
    margin-top: 50px;
}
.marginTop100{
    margin-top: 100px;
}
.p30{
    font-size:19px;
}
.max-w-sm{
    max-width: 700px!important;
    text-align: center;
}

.FontSizeTimesNewRoman{
    font-family: "Times New Roman", Times, serif !important;
}

.marginTop25{
    margin-top: 25px;
}
h1.hero--header.balance-text.mx-auto.font-heading.h20.lg-text-3xl{
    text-align: center;
    color: rgba(77, 119, 143, 1);
    font-weight: 550;
    width: 604px;
    font-family: "Times New Roman", Times, serif !important;
}
p.hero--description.balance-text.p30{
    font-size: 17px !important;
    text-align: justify;
    color: rgba(77, 119, 143, 1) !important;
    font-family: "Times New Roman", Times, serif;
    font-weight: 550;
    max-width: 540px;
}
.mobile{
    font-size: 17px !important;
    /* margin-top: 85px; */
    text-align: center;
    color: rgba(77, 119, 143, 1) !important;
    /* outline: darkseagreen; */
    font-family: "Times New Roman", Times, serif;
    font-weight: 550;
    padding: 25px;
    max-width: 540px
}
.marginTopModifier{
    margin-top: 40px !important;
}
.HospitalName{
    text-align: center;
}
.justifyText{
    text-align: justify;
}
.fontFamilyTimesNewRoman{
    font-family: "Times New Roman", Times, serif !important;
}
a:hover{
    color: #004d99;
}
.uppercase {
    text-transform: uppercase!important;
}
.mt-2 {
    margin-top: 20px!important;
}
.mb-1 {
    margin-bottom: 10px!important;
}
.leading-normal {
    line-height: 1.5!important;
}
.text-xs {
    font-size: .75rem!important;
}
.block {
    display: block!important;
}
a {
    text-decoration: underline;
    /* color: #036; */
    font-weight: 500;
    transition: color .15s linear;
    -webkit-tap-highlight-color: rgba(0,0,0,.1);
    outline: none;
}
.marginLeft20{
    margin-left: 20;
}
.marginLeft30{
    margin-left: 30;
}
.imageOfText{
    padding-right:10px;
}

.cardContent{
    max-height: 100px;
}
.colorWhite{
    background-color: white;
}
.fontSize26{
    font-size: 37px !important;
}
.css-1ndgitk{
    padding-left: 16px !important;
    padding-right: 2px;
}
.fontSize16{
    font-size: 16px !important;
}
/* .MuiCardContent-root.css-46bh2p-MuiCardContent-root{
    height: 230px;
} */
.marginBottom20{
    margin-bottom: 20px;
}
.marginBottom30{
    margin-bottom: 30px !important;
}
.marginTop30{
    margin-top: 30px !important;
}
.marginLeft0{
    margin-left: 0 !important;
}
.marginTop15{
    margin-top: 15px !important;
}
@media only screen and (min-width: 1281px){
   
    .mt-2 {
        line-height: 1.3em;
        margin-top: 0 !important;
    }
    .leading-normal{
        font-size: 18px !important;
    }
    .fontSize16{
        font-size: 20px !important;
    }
    .fontSize26{
        font-size: 28px !important;
    }
    .fontSize30{
        font-size: 30px !important;
    }
    /* .MuiCardContent-root.css-46bh2p-MuiCardContent-root{
        height: 230px !important;
    } */
    .imageBody{
        position: relative;
        width: 100%;
        min-height: 900px;
        height: 100%;
        background-size: 100% 100%;
    }
}
.fontSize17{
    font-size: 17px !important;
}
.textDecorativeNone{
    text-decoration: none !important;
}
.card-horizontal {
    display: flex;
    flex: 1 1 auto;
}
.borderOfCard{
    background-color: rgb(255, 255, 255);
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    overflow: hidden;
    display: flex;
}
.headerTitle{
    text-align: justify;
}

.header{
    text-align: center;
}
.fontSize18{
    font-size:18px !important
}
.colorBrown{
    color:rgba(155, 155, 148, var(--tw-text-opacity)) !important;
    --tw-text-opacity: 1 !important;
}
.fontsize35{
    font-size:35px !important
}
.marginTop30Per{
    margin-top:  30% !important;
}
.paddingBottom10{
    padding-bottom: 10px !important;
}

.SpacePadding10{
    padding: 10px !important;
}
.SpacePadding8{
    padding: 8px !important;
}
.SpacePadding15{
    padding: 15px !important;
}
.textAlignCenterMobile{
    text-align: center;
    
}
.marginTop21Per{
    margin-top: 21% !important;
}
.boarder1px{
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
}
.paddingTop10{
    padding-top: 10px !important;
}
.paddingTop15{
    padding-top: 15px !important;
}
.paddingTop5{
    padding-top: 5px !important;
}
.marginTop40Per{
    margin-top:40% !important;
}
.marginTop5Per{
    padding-top: 5% !important;
}
.marginTop9Per{
    padding-top: 9% !important;
}
.marginLeft40{
    margin-left: 40px !important;
}
.fontFamilyCaveat{
    font-family: 'Caveat', cursive;
}
.otro-blockquote{
    padding: 15px;
}
.fontSize22{
    font-size: 22px !important;
}
.helpusText{
    --tw-text-opacity: 1 !important;
    font-family: Sitka Banner;
    font-size: 45px;
    font-weight: 600;
}
.suggested-amount-box{
    width: 25% !important;
}
.marginBottom30{
    margin-bottom: 30px !important;
}
.marginBottom20{
    margin-bottom: 20px !important;
}
.ImageIndex1{
    object-fit: contain;
}
.homeQuote{
    color: rgba(77, 119, 143, 1) !important;
}
.ImageOfFounder{
    border-radius: 20px !important;
}
img.newsDisplayImages{
    height: auto !important;
    width: 100%;
    border-radius: 50px;
}
.backgroundColorTransparent{
    background-color: transparent !important;
}
.marginTop40{
    margin-top: 40px !important;
}