/* @import url("//maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover{
	background-color: lightgoldenrodyellow !important;
}
.active{
  background-color: rgba(120, 186, 230, 0.7);
}
.navbar-icon-top .navbar-nav .nav-link > .fa {
  position: relative;
  width: 36px;
  font-size: 24px;
}

.navbar-icon-top .navbar-nav .nav-link > .fa > .badge {
  font-size: 0.75rem;
  position: absolute;
  right: 0;
  font-family: sans-serif;
}

.navbar-icon-top .navbar-nav .nav-link > .fa {
  top: 3px;
  line-height: 12px;
}

.navbar-icon-top .navbar-nav .nav-link > .fa > .badge {
  top: -10px;
}

@media (min-width: 576px) {
  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link {
    text-align: center;
    display: table-cell;
    height: 70px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link > .fa {
    display: block;
    width: 48px;
    margin: 2px auto 4px auto;
    top: 0;
    line-height: 24px;
  }

  .navbar-icon-top.navbar-expand-sm .navbar-nav .nav-link > .fa > .badge {
    top: -7px;
  }
}

@media (min-width: 768px) {
  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link {
    text-align: center;
    display: table-cell;
    height: 70px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link > .fa {
    display: block;
    width: 48px;
    margin: 10px auto 4px auto;
    top: 0;
    line-height: 24px;
  }

  .navbar-icon-top.navbar-expand-md .navbar-nav .nav-link > .fa > .badge {
    top: -7px;
  }
}

@media (min-width: 992px) {
  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link {
    text-align: center;
    display: table-cell;
    height: 70px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link > .fa {
    display: block;
    width: 48px;
    margin: 2px auto 4px auto;
    top: 0;
    line-height: 24px;
  }

  .navbar-icon-top.navbar-expand-lg .navbar-nav .nav-link > .fa > .badge {
    top: -7px;
  }
}

@media (min-width: 1200px) {
  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link {
    text-align: center;
    display: table-cell;
    height: 70px;
    vertical-align: middle;
    padding-top: 0;
    padding-bottom: 0;
  }

  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link > .fa {
    display: block;
    width: 48px;
    margin: 2px auto 4px auto;
    top: 0;
    line-height: 24px;
  }

  .navbar-icon-top.navbar-expand-xl .navbar-nav .nav-link > .fa > .badge {
    top: -7px;
  }
}

.social-part .fa{
    padding-right:20px;
    font-size: 24px;
    color: rgba(77, 119, 143, 1);
}
div#navbarSupportedContent{
  margin-left:50px
} */


.navbar {
  color: rgba(77, 119, 143, 1);
  background-color: transparent;
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
}

.navbar-nav .nav-item .nav-link {
  color: rgba(77, 119, 143, 1);
  font-size: 15px;
  padding: 8px;
  transition: color 0.3s;
}

.navbar-nav .nav-item .nav-link:hover {
  color: rgb(146, 203, 230);
}

.navbar-toggler-icon {
  background-color: rgba(77, 119, 143, 1) !important;
}

img.logoImage {
  height: 30px;
}

span.charityName {
  text-decoration: none;
  font-size: 18px;
  color: rgba(77, 119, 143, 1);
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.dropdown-menu {
  display: flexbox;
}

.MuiCardContent-root.cardContextNavbar.css-46bh2p-MuiCardContent-root {
  margin: 20px;
}

@media only screen and (min-width: 1025px) {

  .nav-bar-section .dropdown a,
  .nav-bar-section .dropdown input[type=submit],
  .nav-bar-section .dropdown .country-dropdown-option {
    padding: 12px;
    white-space: nowrap;
    transition: background .15s linear;
  }
}

@media only screen and (min-width: 1025px) {

  .nav-bar-section .dropdown a,
  .nav-bar-section .dropdown li,
  .nav-bar-section .dropdown input[type=submit],
  .nav-bar-section .dropdown .country-dropdown-option {
    display: block;
    width: 100%;
    color: #fff;
    font-weight: 400;
  }
}

.transition-opacity {
  transition-property: opacity !important;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1) !important;
  transition-duration: .15s !important;
}

.underline {
  text-decoration: underline !important;
}

.text-cw-navy {
  --tw-text-opacity: 1 !important;
  color: #036 !important;
  color: rgba(0, 51, 102, var(--tw-text-opacity)) !important;
}

.px-3 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.mt-1 {
  margin-top: 10px !important;
}

.font-medium {
  font-weight: 500 !important;
}

.block {
  display: block !important;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(125, 176, 99);
}

.navbar {
  padding-top: 0 !important
}

.navbar-expand-lg .navbar-nav {
  margin-top: 6px !important;
}

img.twitterIcon {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 1em;
  height: 1em;
  display: inline-block;
  fill: currentColor;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-size: 1.5rem;
}

@media only screen and (min-width: 1025px) and (max-width:1280px) {
  .navbar-nav .nav-item .nav-link {
    color: rgb(21 86 123);
    font-size: 13px !important;
    padding: 8px;
    transition: color 0.3s;
  }
}

@media only screen and (min-width: 921px) and (max-width: 1024px) {
  .navbar-nav .nav-item .nav-link {
    color: rgba(77, 119, 143, 1);
    font-size: 12px !important;
    padding: 8px;
    transition: color 0.3s;
  }
}
@media only screen and (min-width: 781px) and (max-width: 920px) {
  #mainMenu li > a {
    font-size: 12px !important;
    letter-spacing: 1px;
    color: rgba(77, 119, 143, 1);
    font-weight: 500;
    position: relative;
    z-index: 1;
    text-decoration: none;
  }
}
@media only screen and (min-width: 481px) and (max-width: 780px) {
  #mainMenu li > a {
    font-size: 12px !important;
    letter-spacing: 1px;
    color: rgba(77, 119, 143, 1);
    font-weight: 500;
    position: relative;
    z-index: 1;
    text-decoration: none;
  }
}
@media screen and (max-width: 480px) {
  #mainMenu li > a {
    font-size: 12px !important;
    letter-spacing: 1px;
    color: rgba(77, 119, 143, 1);
    font-weight: 500;
    position: relative;
    z-index: 1;
    text-decoration: none;
  }
}
.redFleshColor{
  color: 	#EA8F83 !important;
}