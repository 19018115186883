.tabControl{
    display: flex;
    justify-content: center;
}
.eventTextMiddle{
    margin: auto;
}
.marginBottom10{
    margin-bottom: 10px;
}
.middle{
    text-align: center;
}
.fontSize31{
    font-size: 31px !important;
}

@import url(./emailtemplate.css);
@media only screen and (min-width: 315px) and (max-width: 327px){
    .DivHandRaise {
        position: absolute;
        top: 18% !important;
    }
    .fontText{

    }
}
@media only screen and (min-width: 320px) and (max-width: 420px){
    
    .paraHandRaise{
        padding-top: 30px;
    }
    .paraHandRaise {
        min-width:350px;
        display:inline-block;
        -moz-box-sizing:border-box;
        box-sizing:border-box;
        font-size: 29px !important;
        color: rgba(77,119,143,1);
        margin:auto
    }
    
}

@media only screen and (min-width: 421px) and (max-width: 480px){
   
    .paraHandRaise{
        padding-top: 80px;
    }
    .DivHandRaise {
        position: absolute;
        top: 13%;
    }
}
@media only screen and (min-width: 481px) and (max-width: 768px){
.displaySpinCenter{
    display: flex;
    justify-content: center;
    height: 650px;
    max-width: 450px;
    margin: auto;
}
}
@media only screen and (min-width: 769px) and (max-width: 1024px){

}

@media only screen and (min-width: 1025px) and (max-width: 1200px){

}
@media only screen and (min-width: 1201px){

}

.image {
    float: left;
    margin-right: 10px;
    padding-left: 10px;
    height: 300px; /* Adjust the spacing between image and text */
}

/* Clear the float to prevent layout issues */
.containerAboutUs::after {
    content: "";
    display: table;
    clear: both;
}
.ImageFixedSizeDiv{
    height: 200px;
    width: 50%;
}
.containerAboutUs{
    margin-top: 20px;
}


img.imageDisplay{
    height: 700px;
    width: 100%;
    max-width: 700px;
    border-radius: 50px;
}
.divNewImage{
    max-width: 750px;
    margin: 0 auto;
}

div.floatright {
    margin: 1em;
    position: relative;
    clear: left;
    float: left;
}

.floatright {
    float: left;
    clear: left;
    position: relative;
    margin: 0.2em 0 0.8em 1.4em;
}
.newsImage{
    height: 420px;
    width: 520px;
}
img.newsDisplayImages {
    height: 450px;
    width: 520px;
    border-radius: 20px;
}